import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, sendOtp, verifyOtp } from '../services/authService';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isOtpLogin, setIsOtpLogin] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home if the user is already authenticated
    if (localStorage.getItem('authToken')) {
      navigate('/');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await login(phoneNumber, password);
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('loginData', JSON.stringify({ timestamp: new Date() }));
      navigate('/dashboard');
    } catch (error) {
      setError('Invalid phone number or password');
    }
  };

  const handleOtpLogin = async (e) => {
    e.preventDefault();
    if (!phoneNumber) {
      setError('Please enter your phone number');
      return;
    }
    try {
      await sendOtp(phoneNumber);
      setIsOtpSent(true);
      setError('');
      
    } catch (error) {
      setError('Failed to send OTP');
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    try {
      const data = await verifyOtp(phoneNumber, otp);
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('loginData', JSON.stringify({ timestamp: new Date() }));
      navigate('/dashboard');
    } catch (error) {
      setError('Invalid or expired OTP');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 font-[sans-serif] text-[#333] h-full md:min-h-screen p-4">
      <div className="grid justify-center max-w-md mx-auto">
        <div>
          <img src="https://readymadeui.com/login-image.webp" className="w-full object-cover" alt="login-image" />
        </div>
        <div className="bg-white rounded-2xl p-6 -mt-24 relative z-10 shadow-[0_2px_16px_-3px_rgba(6,81,237,0.3)]">
          <div className="mb-10">
            <h3 className="text-3xl font-extrabold text-blue-600">{isOtpLogin ? (isOtpSent ? 'Verify OTP' : 'Login with OTP') : 'Sign in'}</h3>
          </div>
          {error && <div className="mb-4 text-red-500 text-center">{error}</div>}
          {!isOtpLogin || !isOtpSent ? (
            <form onSubmit={isOtpLogin ? handleOtpLogin : handleLogin}>
              <div className="relative flex items-center mb-8">
                <input
                  name="phoneNumber"
                  type="text"
                  required
                  className="w-full text-sm border-b border-gray-300 focus:border-blue-600 px-2 py-3 outline-none"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-[18px] h-[18px] absolute right-2" viewBox="0 0 682.667 682.667">
                  <defs>
                    <clipPath id="a" clipPathUnits="userSpaceOnUse">
                      <path d="M0 512h512V0H0Z" data-original="#000000"></path>
                    </clipPath>
                  </defs>
                  <g clipPath="url(#a)" transform="matrix(1.33 0 0 -1.33 0 682.667)">
                    <path fill="none" stroke-miterlimit="10" stroke-width="40" d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z" data-original="#000000"></path>
                    <path d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z" data-original="#000000"></path>
                  </g>
                </svg>
              </div>
              {!isOtpLogin && (
                <>
                  <div className="relative flex items-center mb-4">
                    <input
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      required
                      className="w-full text-sm border-b border-gray-300 focus:border-blue-600 px-2 py-3 outline-none pr-10"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 top-4 px-3 flex items-center text-gray-600"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <AiFillEyeInvisible size={24} /> : <AiFillEye size={24} />}
                    </button>
                  </div>
                  <div className="mb-8 text-left">
                    <a href="/forgot-password" className="text-sm text-blue-600 hover:underline">
                      Forgot Password?
                    </a>
                  </div>
                </>
              )}
              <button
                type="submit"
                className="w-full py-2.5 px-4 text-sm font-semibold rounded-full text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                {isOtpLogin ? 'Send OTP' : 'Sign in'}
              </button>
            </form>
          ) : (
            <form onSubmit={handleOtpVerification}>
              <div className="relative flex items-center mb-8">
                <input
                  name="otp"
                  type="text"
                  required
                  className="w-full text-sm border-b border-gray-300 focus:border-blue-600 px-2 py-3 outline-none"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-[18px] h-[18px] absolute right-2" viewBox="0 0 128 128">
                  <path d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z" data-original="#000000"></path>
                </svg>
              </div>
              <button
                type="submit"
                className="w-full py-2.5 px-4 text-sm font-semibold rounded-full text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Verify OTP
              </button>
            </form>
          )}
          {isOtpSent && (
            <div className="mt-8 text-center text-sm">
              <button
                className="text-blue-600 hover:underline"
                onClick={handleOtpLogin}
              >
                Resend OTP
              </button>
            </div>
          )}
          <div className="mt-8 text-center text-sm">
            <button
              className="text-blue-600 hover:underline"
              onClick={() => setIsOtpLogin(!isOtpLogin)}
            >
              {isOtpLogin ? 'Sign in with password' : 'Sign in with OTP'}
            </button>
          </div>
          <div className="mt-8 text-center text-sm">
            <a href="/register" className="text-blue-600 hover:underline">
              Create New Account
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
