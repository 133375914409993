import React from 'react';
import { Link } from 'react-router-dom';
import banner1 from './images/banner-1-bg.jpg';
import banner2 from './images/banner-2-bg.jpg';

const Banner = () => {
  return (
    <section className="banner section py-12">
      <div className="container mx-auto px-6 lg:px-16">
        {/* First two banners side by side */}
        <div className="flex flex-wrap -mx-4 mb-6">
          <div className="w-full md:w-1/2 px-4 mb-6 md:mb-0">
            <div
              className="single-banner bg-cover bg-center bg-no-repeat rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105"
              style={{ backgroundImage: `url(${banner1})` }}
            >
              <div className="content p-10 md:p-8 lg:p-10 bg-opacity-70 bg-black text-white">
                <h2 className="text-2xl font-bold">Smart Watch 2.0</h2>
                <p className="mt-4 text-sm">
                Smart Watch 2.0 combines sleek design with advanced functionality. Featuring a Space Gray Aluminum Case and a Black/Volt Real Sport Band, this smartwatch is perfect for those who want to stay connected and stylish. It offers fitness tracking, notifications, and customizable watch faces, making it a versatile accessory for both everyday wear and intense workouts.
                </p>
                <div className="button mt-6">
                  <Link to={`/home?category=accessory`} className="btn bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <div
              className="single-banner bg-cover bg-center bg-no-repeat rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105"
              style={{ backgroundImage: `url(${banner2})` }}
            >
              <div className="content p-10 md:p-8 lg:p-10 bg-opacity-70 bg-black text-white">
                <h2 className="text-2xl font-bold">Smart Headphone</h2>
                <p className="mt-4 text-sm">
                Smart Headphone delivers high-quality sound with modern features. Designed for ultimate comfort and excellent sound quality, these headphones are perfect for enjoying your favorite music, podcasts, or calls. With advanced noise cancellation, long battery life, and a sleek design, they provide an immersive audio experience whether you're at home or on the go.
                </p>
                <div className="button mt-6">
                  <Link to={`/home?category=accessory`} className="btn bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300">
                    Shop Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
