import React, { useState, useEffect } from 'react';
import { AiFillHome, AiFillPhone } from 'react-icons/ai';
import { FcLike } from 'react-icons/fc';
import { ImYelp } from 'react-icons/im';
import { FaOpencart, FaPowerOff } from 'react-icons/fa';
import { FaAddressCard } from 'react-icons/fa';
import axios from 'axios';
import AddressCard from '../components/AddressCard';
import MyEmi from '../components/MyEmi';
import { useNavigate } from 'react-router-dom';
import Order from '../components/Order';

const SettingsPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('profile');
  const [userDetails, setUserDetails] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    profileImg: '',
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('https://lapkart1-1.onrender.com/api/user/me', {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });
        setUserDetails(response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        'https://lapkart1-1.onrender.com/api/user/me',
        userDetails,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        }
      );
      setUserDetails(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('profileImg', file);

    try {
      const response = await axios.post('https://lapkart1-1.onrender.com/api/user/profile/upload', formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('authToken')}` 
        },
      });
      setUserDetails({ ...userDetails, profileImg: response.data.profilePicture });
      localStorage.setItem('user', JSON.stringify({ ...userDetails, profileImg: response.data.profilePicture }));
      alert('Profile picture updated successfully');
    } catch (error) {
      console.error('Error updating profile picture:', error);
      alert('Failed to update profile picture');
    }
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      alert('Failed to log out');
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'profile':
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Profile</h3>
            <form onSubmit={handleUpdateProfile} className="space-y-4">
              <div className="flex justify-center mb-4">
                <img src={userDetails.profileImg || 'default-avatar.png'} alt="Profile" className="w-24 h-24 rounded-full object-cover" />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  value={userDetails.fullName}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Email</label>
                <input
                  type="email"
                  name="email"
                  value={userDetails.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={userDetails.phoneNumber}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Profile Picture</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleProfilePictureChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
              >
                Update Profile
              </button>
            </form>
          </div>
        );
      case 'address':
        return (
          <div>
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Address</h3>
            <AddressCard />
          </div>
        );
      case 'myEmi':
        return (
          <div>
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">My Emi</h3>
            <MyEmi />
          </div>
        );
      case 'order':
        return (
          <div>
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Orders</h3>
            <Order /> {/* Ensure this component fetches and displays orders correctly */}
          </div>
        );
      case 'wishlists':
        return (
          <div>
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Wishlists</h3>
            {/* Add content for wishlists here */}
          </div>
        );
      case 'contact':
        return (
          <div>
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Contact</h3>
            <p className="mb-2">This is some placeholder content for the Contact tab's associated content.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-4 md:p-6 lg:p-8">
      <div className="flex flex-col md:flex-row">
        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="md:hidden flex items-center px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 mb-4"
        >
          Menu
        </button>

        {/* Navigation Menu (Mobile) */}
        {isMenuOpen && (
          <div className="md:hidden fixed inset-0 bg-white shadow-lg z-50 flex flex-col">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="self-end p-4"
            >
              Close
            </button>
            <ul className="flex flex-col">
              <li>
                <button
                  onClick={() => { setActiveTab('profile'); setIsMenuOpen(false); }}
                  className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'profile' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
                >
                  <AiFillHome className="inline-block mr-2" />
                  Profile
                </button>
              </li>
              <li>
                <button
                  onClick={() => { setActiveTab('address'); setIsMenuOpen(false); }}
                  className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'address' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
                >
                  <FaAddressCard className="inline-block mr-2" />
                  Address
                </button>
              </li>
              <li>
                <button
                  onClick={() => { setActiveTab('myEmi'); setIsMenuOpen(false); }}
                  className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'myEmi' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
                >
                  <ImYelp className="inline-block mr-2" />
                  My Emi
                </button>
              </li>
              <li>
                <button
                  onClick={() => { setActiveTab('order'); setIsMenuOpen(false); }}
                  className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'order' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
                >
                  <FcLike className="inline-block mr-2" />
                  Orders
                </button>
              </li>
              <li>
                <button
                  onClick={() => { setActiveTab('wishlists'); setIsMenuOpen(false); }}
                  className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'wishlists' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
                >
                  <AiFillHome className="inline-block mr-2" />
                  Wishlists
                </button>
              </li>
              <li>
                <button
                  onClick={() => { setActiveTab('contact'); setIsMenuOpen(false); }}
                  className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'contact' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
                >
                  <AiFillPhone className="inline-block mr-2" />
                  Contact
                </button>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 rounded-md text-gray-900 bg-gray-50 hover:bg-gray-100"
                >
                  <FaPowerOff className="inline-block mr-2" />
                  Logout
                </button>
              </li>
            </ul>
          </div>
        )}

        {/* Sidebar (Desktop) */}
        <div className="hidden md:flex md:flex-col md:w-1/4 md:pr-6">
          <button
            onClick={() => setActiveTab('profile')}
            className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'profile' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
          >
            <AiFillHome className="inline-block mr-2" />
            Profile
          </button>
          <button
            onClick={() => setActiveTab('address')}
            className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'address' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
          >
            <FaAddressCard className="inline-block mr-2" />
            Address
          </button>
          <button
            onClick={() => setActiveTab('myEmi')}
            className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'myEmi' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
          >
            <ImYelp className="inline-block mr-2" />
            My Emi
          </button>
          <button
            onClick={() => setActiveTab('order')}
            className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'order' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
          >
            <FaOpencart className="inline-block mr-2" />
            Orders
          </button>
          <button
            onClick={() => setActiveTab('wishlists')}
            className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'wishlists' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
          >
            <FcLike className="inline-block mr-2" />
            Wishlists
          </button>
          <button
            onClick={() => setActiveTab('contact')}
            className={`w-full text-left px-4 py-2 rounded-md ${activeTab === 'contact' ? 'text-white bg-blue-700' : 'text-gray-900 bg-gray-50 hover:bg-gray-100'}`}
          >
            <AiFillPhone className="inline-block mr-2" />
            Contact
          </button>
          <button
            onClick={handleLogout}
            className="w-full text-left px-4 py-2 rounded-md text-gray-900 bg-gray-50 hover:bg-gray-100"
          >
            <FaPowerOff className="inline-block mr-2" />
            Logout
          </button>
        </div>

        {/* Main Content */}
        <div className="flex-1">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;



// import React, { useState, useEffect } from 'react';
// import { AiFillHome, AiFillPhone } from 'react-icons/ai';
// import { FcLike } from "react-icons/fc";
// import { ImYelp } from "react-icons/im";
// import { FaPowerOff, FaRectangleList } from "react-icons/fa6";
// import { FaAddressCard } from "react-icons/fa";
// import axios from 'axios';
// import AddressCard from '../components/AddressCard';
// import MyEmi from '../components/MyEmi';
// import { useNavigate } from 'react-router-dom';
// import Order from '../components/Order';


// const SettingsPage = () => {
//   const navigate=useNavigate();
//   const [activeTab, setActiveTab] = useState('profile');
//   const [userDetails, setUserDetails] = useState({
//     fullName: '',
//     email: '',
//     phoneNumber: '',
//     profileImg: '',
//   });

//   useEffect(() => {
//     // Fetch user details from API
//     const fetchUserDetails = async () => {
//       try {
//         const response = await axios.get('https://lapkart1-1.onrender.com/api/user/me', {
//           headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
//         });
//         setUserDetails(response.data);
//         // Optionally store user details in local storage
//         localStorage.setItem('user', JSON.stringify(response.data));
//       } catch (error) {
//         console.error('Error fetching user details:', error);
//       }
//     };

//     fetchUserDetails();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setUserDetails({ ...userDetails, [name]: value });
//   };

//   const handleUpdateProfile = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.put(
//         'https://lapkart1-1.onrender.com/api/user/me',
//         userDetails,
//         {
//           headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
//         }
//       );
//       // Update user details in state and local storage
//       setUserDetails(response.data);
//       console.log(response.data);
//       localStorage.setItem('user', JSON.stringify(response.data));
//       alert('Profile updated successfully');
//     } catch (error) {
//       console.error('Error updating profile:', error);
//       alert('Failed to update profile');
//     }
//   };

//   const handleProfilePictureChange = async (e) => {
//     const file = e.target.files[0];
//     const formData = new FormData();
//     formData.append('profileImg', file);
  
//     try {
//       // Make API call to update profile picture
//       const response = await axios.post('https://lapkart1-1.onrender.com/api/user/profile/upload', formData, {
//         headers: { 
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${localStorage.getItem('authToken')}` 
//         },
//       });
      
//       // Update user details with the new profile picture URL
//       setUserDetails({ ...userDetails, profileImg: response.data.profilePicture });
//       localStorage.setItem('user', JSON.stringify({ ...userDetails, profileImg: response.data.profilePicture }));
//       alert('Profile picture updated successfully');
//     } catch (error) {
//       console.error('Error updating profile picture:', error);
//       alert('Failed to update profile picture');
//     }
//   };
//   const handelLogout=()=>{
// setActiveTab('logout');
// localStorage.removeItem('authToken');
// localStorage.removeItem('user');
// navigate('/login');
//   }
  

//   const renderContent = () => {
//     switch (activeTab) {
//       case 'profile':
//         return (
//           <div>
//             <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Profile Tab</h3>
//             <form onSubmit={handleUpdateProfile} className="space-y-4">
//               <div>
//                 <img src={userDetails.profileImg} alt="Profile" className="rounded-full w-24 h-24 mx-auto" />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
//                   Full Name
//                 </label>
//                 <input
//                   type="text"
//                   name="fullName"
//                   value={userDetails.fullName}
//                   onChange={handleInputChange}
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   name="email"
//                   value={userDetails.email}
//                   onChange={handleInputChange}
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
//                   Phone Number
//                 </label>
//                 <input
//                   type="text"
//                   name="phoneNumber"
//                   value={userDetails.phoneNumber}
//                   onChange={handleInputChange}
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
//                   Profile Picture
//                 </label>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={handleProfilePictureChange}
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
//                 />
//               </div>
//               <button
//                 type="submit"
//                 className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
//               >
//                 Update Profile
//               </button>
//             </form>
//           </div>
//         );
//       case 'address':
//         return (
//           <div>
//             <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">address Tab</h3>
//             <AddressCard/>
//           </div>
//         );
//       case 'myEmi':
//         return (
//           <div>
//             <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">myEmi Tab</h3>
//             <MyEmi/>
//           </div>
//         );
//       case 'order':
//         return(
//           <div>
//             <h3 className='text-lg font-bold text-gray-900 dark:text-white mb-2'>Order</h3>
//             <Order/>
//           </div>
//         );
//       case 'wishlists':
//         return(
//           <div>
//             <h3 className='text-lg font-bold text-gray-900 dark:text-white mb-2'>Wishlists</h3>

//           </div>
//         );
//       case 'contact':
//         return (
//           <div>
//             <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Contact Tab</h3>
//             <p className="mb-2">This is some placeholder content for the Contact tab's associated content.</p>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>

//       <div className="md:flex p-6">
//         <ul className="flex flex-col space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:mr-4 mb-4 md:mb-0">
//           <li>
//             <button
//               onClick={() => setActiveTab('profile')}
//               className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${activeTab === 'profile' ? 'text-white bg-blue-700 dark:bg-blue-600' : 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'}`}
//             >
//               <AiFillHome className="w-4 h-4 mr-2" />
//               Profile
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() => setActiveTab('address')}
//               className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${activeTab === 'address' ? 'text-white bg-blue-700 dark:bg-blue-600' : 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'}`}
//             >
//               <FaAddressCard className="w-4 h-4 mr-2" />
//               Address
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() => setActiveTab('order')}
//               className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${activeTab === 'order' ? 'text-white bg-blue-700 dark:bg-blue-600' : 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'}`}
//             >
//               <FaRectangleList className="w-4 h-4 mr-2" />
//               Orders
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() => setActiveTab('myEmi')}
//               className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${activeTab === 'myEmi' ? 'text-white bg-blue-700 dark:bg-blue-600' : 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'}`}
//             >
//               <ImYelp  className="w-4 h-4 mr-2" />
//               My Emi
//             </button>
//           </li>
          
//           <li>
//             <button
//               onClick={() => setActiveTab('wishlists')}
//               className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${activeTab === 'wishlists' ? 'text-white bg-blue-700 dark:bg-blue-600' : 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'}`}
//             >
//               <FcLike className="w-4 h-4 mr-2" />
//               Wishlists
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() => setActiveTab('contact')}
//               className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${activeTab === 'contact' ? 'text-white bg-blue-700 dark:bg-blue-600' : 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'}`}
//             >
//               <AiFillPhone className="w-4 h-4 mr-2" />
//               Contact
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={handelLogout}
//               className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${activeTab === 'logout' ? 'text-white bg-blue-700 dark:bg-blue-600' : 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'}`}
//             >
//               <FaPowerOff className="w-4 h-4 mr-2" />
//               Logout
//             </button>
//           </li>
//         </ul>
//         <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
//           {renderContent()}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SettingsPage;
