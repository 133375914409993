import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, filter, onFilterChange, onApplyFilters, onResetFilters }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <h3 className="text-xl font-bold mb-4">Filters</h3>
        <div className="mb-4">
          <label className="block text-gray-700">Start Date:</label>
          <input
            type="date"
            name="startDate"
            value={filter.startDate}
            onChange={onFilterChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">End Date:</label>
          <input
            type="date"
            name="endDate"
            value={filter.endDate}
            onChange={onFilterChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Min Amount:</label>
          <input
            type="number"
            name="minAmount"
            value={filter.minAmount}
            onChange={onFilterChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Max Amount:</label>
          <input
            type="number"
            name="maxAmount"
            value={filter.maxAmount}
            onChange={onFilterChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onResetFilters}
            className="bg-gray-500 text-white p-2 rounded"
          >
            Reset
          </button>
          <button
            onClick={onApplyFilters}
            className="bg-blue-500 text-white p-2 rounded"
          >
            Apply
          </button>
        </div>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-700"
        >
          &times;
        </button>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
