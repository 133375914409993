import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";

function OrderList() {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false); // State for filter modal
  const [newStatus, setNewStatus] = useState("");
  const [filterType, setFilterType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://lapkart1-1.onrender.com/api/sellers/getAllOrders');
        setOrders(response.data);
        setLoading(false);
      } catch (err) {
        const errorMessage = err.response ? err.response.data.message : err.message;
        console.error('Error fetching orders:', errorMessage);
        setError(errorMessage);
        setLoading(false);
      }
    };
    
    fetchOrders();
  }, []);

  const handleEditStatus = (order) => {
    setSelectedOrder(order);
    setShowStatusModal(true);
    setNewStatus(order.orderStatus); // Set initial status in the modal
  };

  const handleCloseStatusModal = () => {
    setShowStatusModal(false);
    setSelectedOrder(null);
    setNewStatus(""); // Clear new status on modal close
  };

  const handleCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  const updateOrderStatus = async () => {
    try {
      // Update the local state
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === selectedOrder._id ? { ...order, orderStatus: newStatus } : order
        )
      );
  
      // Update the database
      await axios.put(`https://lapkart1-1.onrender.com/api/orders/${selectedOrder._id}`, {
        orderStatus: newStatus
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });
  
      // Close the modal
      handleCloseStatusModal();
    } catch (err) {
      console.error('Error updating order status:', err.response ? err.response.data : err.message);
      // Optionally, handle the error (e.g., show a notification)
    }
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterType("");
    setStartDate("");
    setEndDate("");
    setStatusFilter("");
    setSearchTerm(""); // Clear search term
  };

  const toggleFilterModal = () => {
    setShowFilterModal(true);
  };

  const filteredOrders = orders.filter((order) => {
    if (filterType && order.orderType !== filterType) {
      return false;
    }
    if (startDate && new Date(order.createdAt) < new Date(startDate)) {
      return false;
    }
    if (endDate && new Date(order.createdAt) > new Date(endDate)) {
      return false;
    }
    if (statusFilter && order.orderStatus !== statusFilter) {
      return false;
    }
    if (searchTerm && !(
      order.orderID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.totalAmount.toString().includes(searchTerm)
    )) {
      return false;
    }
    return true;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <section className="flex-grow p-4 overflow-auto">
      <h3 className="text-[32px] text-[#202224] font-bold mb-4">Order Lists</h3>
      
      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search orders..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/2 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-200"
        />
        <button
          onClick={toggleFilterModal}
          className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
        >
          Filter Orders
        </button>
      </div>

      <div className="overflow-x-auto">
        {/* Table section */}
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-2">Order ID</th>
              <th scope="col" className="px-4 py-2">User Name</th> {/* Added column */}
              <th scope="col" className="px-4 py-2">Total Amount</th>
              <th scope="col" className="px-4 py-2">Order Status</th>
              <th scope="col" className="px-4 py-2">Order Date</th>
              <th scope="col" className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-4 py-2">{order.orderID}</td>
                <td className="px-4 py-2">{order.fullName}</td> {/* Added user name */}
                <td className="px-4 py-2">${order.totalAmount}</td>
                <td className="px-4 py-2">
                  {order.orderStatus === "Delivered" && (
                    <span className="bg-green-100 font-semibold text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                      {order.orderStatus}
                    </span>
                  )}
                  {order.orderStatus === "Pending" && (
                    <span className="bg-purple-100 font-semibold text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">
                      {order.orderStatus}
                    </span>
                  )}
                  {order.orderStatus === "In Transit" && (
                    <span className="bg-orange-100 font-semibold text-orange-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-orange-900 dark:text-orange-300">
                      {order.orderStatus}
                    </span>
                  )}
                  {order.orderStatus === "On Hold" && (
                    <span className="bg-yellow-100 font-semibold text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
                      {order.orderStatus}
                    </span>
                  )}
                  {order.orderStatus === "Rejected" && (
                    <span className="bg-red-100 font-semibold text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                      {order.orderStatus}
                    </span>
                  )}
                </td>
                <td className="px-4 py-2">{new Date(order.updatedAt).toLocaleDateString()}</td>
                <td className="px-4 py-2">
                  <button
                    onClick={() => handleEditStatus(order)}
                    className="text-blue-500 hover:underline focus:outline-none"
                  >
                    Edit Order
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Status Modal */}
      <Modal
        isOpen={showStatusModal}
        onRequestClose={handleCloseStatusModal}
        style={{
          content: {
            width: '500px',
            height: '400px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            boxSizing: 'border-box',
          },
        }}
        ariaHideApp={false}
      >
        <h2 className="text-xl font-semibold mb-4">Update Order Status</h2>
        {selectedOrder && (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <p className="mb-2">Order ID: {selectedOrder.orderID}</p>
            <select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              className="mb-4 p-2 border border-gray-300 rounded-md w-full max-w-xs"
            >
              <option value="Delivered">Delivered</option>
              <option value="Pending">Pending</option>
              <option value="In Transit">In Transit</option>
              <option value="On Hold">On Hold</option>
              <option value="Rejected">Rejected</option>
            </select>
            <div className="flex gap-2">
              <button
                onClick={updateOrderStatus}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
              >
                Update
              </button>
              <button
                onClick={handleCloseStatusModal}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>

      {/* Filter Modal */}
      <Modal isOpen={showFilterModal} onRequestClose={handleCloseFilterModal}>
        <h2 className="text-xl font-semibold mb-4">Filter Orders</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Order Type</label>
          <select
            value={filterType}
            onChange={handleFilterChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="">All</option>
            <option value="Online">Online</option>
            <option value="COD">COD</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Order Status</label>
          <select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="">All</option>
            <option value="Delivered">Delivered</option>
            <option value="Pending">Pending</option>
            <option value="In Transit">In Transit</option>
            <option value="On Hold">On Hold</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        <button
          onClick={handleResetFilter}
          className="mr-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none"
        >
          Reset
        </button>
        <button
          onClick={handleCloseFilterModal}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
        >
          Apply Filters
        </button>
        <button
              onClick={handleCloseFilterModal}
              className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none"
            >
              Close
            </button>
      </Modal>
    </section>
  );
}

export default OrderList;
