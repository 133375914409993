import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { format, isValid, parseISO } from 'date-fns';
import Modal from 'react-modal';
import { FaFilter, FaSearch } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';

Modal.setAppElement('#root'); // For accessibility, set the app element

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('latest');
  const [statusFilter, setStatusFilter] = useState('all');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://lapkart1-1.onrender.com/api/orders/orderlist', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        const sortedOrders = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setOrders(sortedOrders);
      } catch (error) {
        console.error('Error fetching orders:', error.message);
        setError(error.response?.data?.message || 'Error fetching orders. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleSortChange = (e) => {
    const sortValue = e.target.value;
    setSortOrder(sortValue);
    const sortedOrders = [...orders].sort((a, b) => {
      if (sortValue === 'latest') {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    setOrders(sortedOrders);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handlePriceChange = (e) => {
    if (e.target.name === 'minPrice') setMinPrice(e.target.value);
    if (e.target.name === 'maxPrice') setMaxPrice(e.target.value);
  };

  const handleDateChange = (e) => {
    if (e.target.name === 'startDate') setStartDate(e.target.value);
    if (e.target.name === 'endDate') setEndDate(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const applyFilters = (order) => {
    const { orderStatus, totalAmount, createdAt, orderID } = order;

    const statusMatch = statusFilter === 'all' || orderStatus === statusFilter;
    const priceMatch = (!minPrice || totalAmount >= minPrice) && (!maxPrice || totalAmount <= maxPrice);
    const dateMatch = (!startDate || (isValid(parseISO(startDate)) && new Date(createdAt) >= new Date(startDate)))
                   && (!endDate || (isValid(parseISO(endDate)) && new Date(createdAt) <= new Date(endDate)));
    const searchMatch = !searchQuery || orderID.toLowerCase().includes(searchQuery.toLowerCase());

    return statusMatch && priceMatch && dateMatch && searchMatch;
  };

  const filteredOrders = orders.filter(applyFilters);

  if (loading) return <div className="text-center py-4">Loading...</div>;

  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-gray-900">Your Orders</h1>

      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <select
            value={sortOrder}
            onChange={handleSortChange}
            className="block w-48 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="latest">Latest Orders</option>
            <option value="oldest">Oldest Orders</option>
          </select>
          <button
            onClick={() => setIsFilterModalOpen(true)}
            className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition"
          >
            <FaFilter className="mr-2" /> Filter
          </button>
        </div>
        <div className="relative w-64">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            className="block w-full pl-10 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Search by Order ID"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        </div>
      </div>

      <Modal
        isOpen={isFilterModalOpen}
        onRequestClose={() => setIsFilterModalOpen(false)}
        contentLabel="Filter Orders"
        className="fixed inset-0 bg-white p-6 max-w-lg mx-auto my-4 border border-gray-300 rounded-lg shadow-lg"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Filters</h2>
          <button
            onClick={() => setIsFilterModalOpen(false)}
            className="text-gray-600 hover:text-gray-900"
          >
            <AiOutlineClose className="text-xl" />
          </button>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-700">Status:</label>
            <select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="all">All Statuses</option>
              <option value="Delivered">Delivered</option>
              <option value="Pending">Pending</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
          <div className="flex space-x-2">
            <input
              type="number"
              name="minPrice"
              value={minPrice}
              onChange={handlePriceChange}
              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Min Price"
            />
            <input
              type="number"
              name="maxPrice"
              value={maxPrice}
              onChange={handlePriceChange}
              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Max Price"
            />
          </div>
          <div className="flex space-x-2">
            <input
              type="date"
              name="startDate"
              value={startDate}
              onChange={handleDateChange}
              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            />
            <input
              type="date"
              name="endDate"
              value={endDate}
              onChange={handleDateChange}
              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <button
            onClick={() => setIsFilterModalOpen(false)}
            className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
          >
            Apply Filters
          </button>
        </div>
      </Modal>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredOrders.map((order) => (
          <div key={order._id} className="bg-white shadow-lg rounded-lg p-6 flex flex-col space-y-4 transition transform hover:-translate-y-1 hover:shadow-xl">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-800">Order ID: {order.orderID}</h2>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${order.orderStatus === 'Delivered' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                {order.orderStatus}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-gray-600">Total Amount: <span className="font-bold">₹{order.totalAmount}</span></p>
              <p className="text-sm text-gray-500">
                {order.createdAt && isValid(parseISO(order.createdAt)) ? format(parseISO(order.createdAt), 'dd MMM yyyy') : 'Invalid Date'}
              </p>
            </div>
            <Link
              to={`/order/${order._id}`}
              className="self-end px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
            >
              View Details
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;
