import React from 'react';
import { NavLink } from 'react-router-dom';
import { MdCategory, MdOutlinePayment } from "react-icons/md";
import {
  RiBillLine,
  RiDashboard3Line,
  RiFileList2Line,
  RiProductHuntLine,
  RiSettings3Line,
  RiShutDownLine,
  RiStockLine
} from 'react-icons/ri';
import { TfiLayoutSlider } from "react-icons/tfi";
import { FaRegUser } from 'react-icons/fa';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <aside className={`w-64 bg-white p-4 shadow-md h-screen fixed top-0 left-0 transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} z-50`}>
      <nav>
        <ul className="text-center">
          <li>
            <NavLink to="/seller/dashboard" className="text-blue-600 font-bold text-3xl" onClick={toggleSidebar}>
              Dash<span className="text-black">Stack</span>
            </NavLink>
          </li>
          <li className="mb-2 mt-6">
            <NavLink
              to="/seller/dashboard"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <RiDashboard3Line className="mr-2" />
              Dashboard
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/seller/product"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <RiProductHuntLine className="mr-2" />
              Products
            </NavLink>
          </li>
          
          <li className="mb-2">
            <NavLink
              to="/seller/order-list"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <RiFileList2Line className="mr-2" />
              Orders
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/seller/slides"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <TfiLayoutSlider className="mr-2" />
              Slides
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/seller/category"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <MdCategory className="mr-2" />
              Categories
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/seller/user-list"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <FaRegUser className="mr-2" />
              Users
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/seller/product-stock"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <RiStockLine className="mr-2" />
              Stock
            </NavLink>
          </li>
          {/* <li className="mb-2">
            <NavLink
              to="/seller/invoice"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <RiBillLine className="mr-2" />
              Invoices
            </NavLink>
          </li> */}
          <li className="mb-2">
            <NavLink
              to="/seller/payments"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <MdOutlinePayment  className="mr-2" />
              Payments
            </NavLink>
          </li>
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 mt-[150px]" />
          <li className="mb-2">
            <NavLink
              to="/seller/settings"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <RiSettings3Line className="mr-2" />
              Settings
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/login"
              className={({ isActive }) => isActive ? 'text-white bg-blue-600 p-2 rounded block flex items-center' : 'text-black p-2 rounded block flex items-center'}
              onClick={toggleSidebar}
            >
              <RiShutDownLine className="mr-2" />
              Logout
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
