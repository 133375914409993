import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MyEmi = () => {
  const [activeTab, setActiveTab] = useState('active');
  const [emis, setEmis] = useState([]);

  useEffect(() => {
    fetchEmis();
  }, []);

  const fetchEmis = async () => {
    try {
      const user = localStorage.getItem('user');
      if (!user) {
        throw new Error('User not found in localStorage');
      }
      const userId = JSON.parse(user)._id;

      const response = await fetch(`https://lapkart1-1.onrender.com/api/emi/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data);
      setEmis(data);
    } catch (error) {
      console.error('Error fetching emis:', error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handlePay = async (emiId) => {
    try {
      const userId = JSON.parse(localStorage.getItem('user'))._id;
      const response = await axios.post('https://lapkart1-1.onrender.com/api/emi/pay-emi', { userId, emiId });
      if (response.data.success) {
        fetchEmis(); // Refresh the EMI list after payment
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error paying EMI:', error);
    }
  };

  // Sort EMIs so unpaid ones come first
  const sortedEmis = [...emis].sort((a, b) => (a.status === 'unpaid' ? -1 : 1));

  // Filter EMIs based on status for the active and paid tabs
  const activeEmis = sortedEmis.filter(emiGroup => emiGroup.status === 'pending' || emiGroup.status === 'unpaid');
  const paidEmis = sortedEmis.filter(emiGroup => emiGroup.status === 'completed');

  // Determine the upcoming EMI
  const upcomingEmi = activeEmis.length > 0 ? activeEmis[0].emiDetails.find(emi => emi.status === 'unpaid') : null;

  return (
    <div>
      <div className="flex">
        <button
          className={`tab-btn ${activeTab === 'active' ? 'inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500 mx-2' : 'mx-2'}`}
          onClick={() => handleTabChange('active')}
        >
          Active EMI
        </button>
        <button
          className={`tab-btn ${activeTab === 'paid' ? 'inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500 mx-2' : 'mx-2'}`}
          onClick={() => handleTabChange('paid')}
        >
          Paid EMI
        </button>
      </div>
      <div>
        {activeTab === 'active' ? (
          activeEmis.map((emiGroup, index) => (
            <Accordion key={index} title={emiGroup.product ? emiGroup.product.title : 'Loading Product Title...'}>
              <EmiList emis={emiGroup} handlePay={handlePay} upcomingEmi={upcomingEmi} />
            </Accordion>
          ))
        ) : (
          paidEmis.map((emiGroup, index) => (
            <Accordion key={index} title={emiGroup.product ? emiGroup.product.title : 'Loading Product Title...'}>
              <EmiList emis={emiGroup} handlePay={handlePay} upcomingEmi={upcomingEmi} />
            </Accordion>
          ))
        )}
      </div>
    </div>
  );
};

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border rounded-md my-4">
      <button
        className="w-full text-left p-4 bg-gray-200 hover:bg-gray-300"
        onClick={toggleAccordion}
      >
        <div className="flex justify-between items-center">
          <span>{title}</span>
          <span>{isOpen ? '▲' : '▼'}</span>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 bg-gray-100 dark:bg-gray-800">
          {children}
        </div>
      )}
    </div>
  );
};

const EmiList = ({ emis, handlePay, upcomingEmi }) => {
  return (
    <div>
      {emis.emiDetails.map((emi, index) => (
        <div key={index} className="py-2">
          <div className="flex justify-between items-center">
            <div>
              {/* Display relevant EMI details */}
              <div>EMI Amount: {emi.amount}</div> {/* Replace with actual field from your backend */}
              <div>Due Date: {new Date(emi.dueDate).toLocaleDateString()}</div>
              <div>Status: {emi.status}</div>
            </div>
            {emi.status === 'unpaid' ? (
              <button 
                className={`bg-blue-500 text-white px-4 py-2 rounded mt-2 ${emi._id !== upcomingEmi?._id ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={() => emi._id === upcomingEmi?._id && handlePay(emi._id)}
                disabled={emi._id !== upcomingEmi?._id}
              >
                Pay
              </button>
            ) : (
              <div className="bg-green-500 text-white px-4 py-2 rounded mt-2">
                Paid
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyEmi;
