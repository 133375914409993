import React from "react";

const TermsAndConditions = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center mb-8">Terms and Conditions</h2>
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">1. Introduction</h3>
          <p className="mb-4">
            Welcome to Mylapkart. These Terms and Conditions ("Terms") govern your use of our website, products, and services (collectively, "Services"). By accessing or using our Services, you agree to be bound by these Terms.
          </p>
          <h3 className="text-lg font-semibold mb-4">2. Use of Services</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Eligibility: You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you are at least 18 years old.</li>
            <li className="mb-2">Account Registration: You may need to create an account to access certain features of our Services. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
            <li>Prohibited Activities: You agree not to engage in any of the following prohibited activities:
              <ul className="list-disc list-inside ml-6">
                <li>(a) using the Services for any unlawful purpose,</li>
                <li>(b) infringing on the intellectual property rights of others,</li>
                <li>(c) interfering with the security or functionality of the Services, or</li>
                <li>(d) engaging in fraudulent or deceptive practices.</li>
              </ul>
            </li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">3. Orders and Payment</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Order Acceptance: All orders are subject to acceptance by Mylapkart. We reserve the right to refuse or cancel any order for any reason, including product availability, errors in pricing, or issues identified by our fraud prevention team.</li>
            <li className="mb-2">Payment: Payment for products and services must be made through the payment methods available on our website. By providing payment information, you represent and warrant that you are authorized to use the payment method and that the information provided is accurate.</li>
            <li>Pricing and Availability: Prices and availability of products are subject to change without notice. We make every effort to provide accurate pricing information, but errors may occur. In the event of a pricing error, we reserve the right to cancel or refuse any orders placed at the incorrect price.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">4. Shipping and Delivery</h3>
          <p className="mb-4">
            Please refer to our Shipping Policy for detailed information about our shipping and delivery practices.
          </p>
          <h3 className="text-lg font-semibold mb-4">5. Returns and Refunds</h3>
          <p className="mb-4">
            Please refer to our Return Policy for detailed information about our returns and refunds practices.
          </p>
          <h3 className="text-lg font-semibold mb-4">6. Intellectual Property</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Ownership: All content and materials available on our website, including text, graphics, logos, and software, are the property of Mylapkart or our licensors and are protected by intellectual property laws.</li>
            <li>Limited License: You are granted a limited, non-exclusive, non-transferable license to access and use the Services for personal, non-commercial purposes. This license does not allow you to reproduce, distribute, or create derivative works from our content without our express written permission.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">7. Disclaimers and Limitation of Liability</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Disclaimer of Warranties: The Services are provided on an "as is" and "as available" basis. Mylapkart makes no warranties, express or implied, regarding the Services, including but not limited to the accuracy, reliability, or availability of the Services.</li>
            <li>Limitation of Liability: To the fullest extent permitted by law, Mylapkart shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Services.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">8. Indemnification</h3>
          <p className="mb-4">
            You agree to indemnify and hold harmless Mylapkart, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in connection with your use of the Services or violation of these Terms.
          </p>
          <h3 className="text-lg font-semibold mb-4">9. Governing Law and Dispute Resolution</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Governing Law: These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Mylapkart is based, without regard to its conflict of law principles.</li>
            <li>Dispute Resolution: Any disputes arising out of or in connection with these Terms shall be resolved through binding arbitration in accordance with the rules of the [Arbitration Association]. The arbitration shall take place in [City, State], and the language of the arbitration shall be English.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">10. Changes to Terms</h3>
          <p className="mb-4">
            Mylapkart reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on our website. Your continued use of the Services after the posting of changes constitutes your acceptance of such changes.
          </p>
          <h3 className="text-lg font-semibold mb-4">11. Contact Information</h3>
          <p className="mb-4">
            If you have any questions or concerns about these Terms, please contact us at:
            <ul className="list-disc list-inside">
              <li>Email: <a href="mailto:support@mylapkart.com" className="text-blue-600">support@mylapkart.com</a></li>
              {/* <li>Phone: +91 72196 55222</li> */}
            </ul>
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
