import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ImageCarousel from './ImageCarousel';

const BuyOnEmi = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { product } = location.state || {};
  const [downPayment, setDownPayment] = useState(0);
  const [loanTerm, setLoanTerm] = useState(3); // Default loan term of 3 months
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const user = localStorage.getItem('user');
    setUserData(JSON.parse(user));
    if (product) {
      setDownPayment((product.price * 0.2).toFixed(2)); // Set down payment to 20% of product price
    }
    console.log(product);
  }, [product]);

  const handleDownPaymentChange = (e) => {
    setDownPayment(e.target.value);
  };

  const handleLoanTermChange = (e) => {
    setLoanTerm(parseInt(e.target.value));
  };

  const handleBuyNow = async () => {
    try {
      const userId = userData._id;
      const productId = product._id;
      console.log(productId);

      const response = await axios.post('https://lapkart1-1.onrender.com/api/orders/create-order', {
        userId,
        productId,
        downPayment: parseFloat(downPayment),
        loanTerm,
        annualInterestRate: 10, // Example annual interest rate of 10%
        processingFee: 1500, // Fixed processing fee
        gstPercentage: 18, // GST percentage
      });

      // Redirect to the EMI component with the order ID
      // navigate(`/emi/${response.data.order._id}`);
    } catch (error) {
      console.error('Error creating order:', error.response ? error.response.data : error.message);
    }
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Buy on EMI</h2>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <ImageCarousel images={product.images} />
        <div className="p-4">
          <h3 className="text-lg font-semibold">{product.title}</h3>
          <p className="text-gray-600">{product.description}</p>
          <div className="mt-2 text-xl font-bold">${product.price}</div>
        </div>
      </div>

      <div className="mt-4">
        <label htmlFor="downPayment" className="block">
          Down Payment:
        </label>
        <input
          type="number"
          id="downPayment"
          value={downPayment}
          onChange={handleDownPaymentChange}
          className="border border-gray-400 p-2 mt-1 w-full"
        />
      </div>

      <div className="mt-4">
        <label htmlFor="loanTerm" className="block">
          Loan Term (months):
        </label>
        <select
          id="loanTerm"
          value={loanTerm}
          onChange={handleLoanTermChange}
          className="border border-gray-400 p-2 mt-1 w-full"
        >
          <option value="3">3</option>
          <option value="6">6</option>
          <option value="9">9</option>
          <option value="12">12</option>
        </select>
      </div>

      <div className="mt-4">
        <button
          onClick={handleBuyNow}
          className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
        >
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default BuyOnEmi;








// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';

// const BuyOnEmi = () => {
//   const location = useLocation();
//   const { product } = location.state || {};
//   const [downPayment, setDownPayment] = useState(0);
//   const [loanTerm, setLoanTerm] = useState(3); // Default loan term of 3 months
//   const annualInterestRate = 10; // Example annual interest rate of 10%

//   if (!product) {
//     return <div>Product not found</div>;
//   }

//   const handleDownPaymentChange = (e) => {
//     setDownPayment(e.target.value);
//   };

//   const handleLoanTermChange = (e) => {
//     setLoanTerm(parseInt(e.target.value));
//   };

//   const calculateEMI = () => {
//     const purchasePrice = product.price;
//     const loanAmount = purchasePrice - downPayment;
    
//     // Adding processing fees and GST
//     const processingFees = 1500; // Example processing fees
//     const gstPercentage = 18; // Example GST percentage
//     const gstAmount = (processingFees * gstPercentage) / 100;
//     const totalLoanAmount = loanAmount + processingFees + gstAmount;
    
//     const monthlyInterestRate = annualInterestRate / 12 / 100;
//     const numberOfMonths = loanTerm;

//     const emi =
//       (totalLoanAmount *
//         monthlyInterestRate *
//         Math.pow(1 + monthlyInterestRate, numberOfMonths)) /
//       (Math.pow(1 + monthlyInterestRate, numberOfMonths) - 1);

//     return emi.toFixed(2);
// };

//   const totalPayment = () => {
//     const emi = calculateEMI();
//     const numberOfMonths = loanTerm;
//     const total = emi * numberOfMonths + parseInt(downPayment);
//     return total.toFixed(2);
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <h2 className="text-2xl font-bold mb-4">Buy on EMI</h2>
//       <div className="bg-white shadow-md rounded-lg overflow-hidden">
//         <img
//           className="h-48 object-cover"
//           src={product.images[0]}
//           alt={product.title}
//         />
//         <div className="p-4">
//           <h3 className="text-lg font-semibold">{product.title}</h3>
//           <p className="text-gray-600">{product.description}</p>
//           <div className="mt-2 text-xl font-bold">${product.price}</div>
//         </div>
//       </div>
      
//       <div className="mt-4">
//         <label htmlFor="downPayment" className="block">
//           Down Payment:
//         </label>
//         <input
//           type="number"
//           id="downPayment"
//           value={downPayment}
//           onChange={handleDownPaymentChange}
//           className="border border-gray-400 p-2 mt-1 w-full"
//         />
//       </div>
      
//       <div className="mt-4">
//         <label htmlFor="loanTerm" className="block">
//           Loan Term (months):
//         </label>
//         <select
//           id="loanTerm"
//           value={loanTerm}
//           onChange={handleLoanTermChange}
//           className="border border-gray-400 p-2 mt-1 w-full"
//         >
//           <option value="3">3</option>
//           <option value="6">6</option>
//           <option value="9">9</option>
//           <option value="12">12</option>
//         </select>
//       </div>
      
//       <div className="mt-4">
//         <h3 className="text-lg font-semibold">EMI: ${calculateEMI()}</h3>
//         <h3 className="text-lg font-semibold">Total Payment: ${totalPayment()}</h3>
//       </div>
//     </div>
//   );
// };

// export default BuyOnEmi;
