import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Modal from './Modal'; // Import the Modal component

const SellerPayments = () => {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    minAmount: '',
    maxAmount: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get('https://lapkart1-1.onrender.com/api/payments');
        setPayments(response.data);
        setFilteredPayments(response.data);
      } catch (error) {
        console.error('Error fetching payments:', error.message || error);
      }
    };

    fetchPayments();
  }, []);

  const applyFilters = () => {
    const { startDate, endDate, minAmount, maxAmount } = filter;
    let filtered = [...payments];

    if (startDate) {
      filtered = filtered.filter(payment => new Date(payment.date) >= new Date(startDate));
    }
    if (endDate) {
      filtered = filtered.filter(payment => new Date(payment.date) <= new Date(endDate));
    }
    if (minAmount) {
      filtered = filtered.filter(payment => payment.amount >= parseFloat(minAmount));
    }
    if (maxAmount) {
      filtered = filtered.filter(payment => payment.amount <= parseFloat(maxAmount));
    }

    setFilteredPayments(filtered);
  };

  const handleFilterChange = (e) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      [e.target.name]: e.target.value
    }));
  };

  const handleApplyFilters = () => {
    applyFilters();
    setIsModalOpen(false);
  };

  const handleResetFilters = () => {
    setFilter({
      startDate: '',
      endDate: '',
      minAmount: '',
      maxAmount: '',
    });
    setFilteredPayments(payments);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-bold">Payments</h3>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Open Filters
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4">
        <h4 className="text-lg font-bold mb-4">Payment List</h4>
        <ul>
          {filteredPayments.length > 0 ? (
            filteredPayments.map(payment => (
              <li key={payment._id} className="border-b border-gray-200 py-2">
                <div className="flex justify-between">
                  <span>{new Date(payment.createdAt).toLocaleDateString()}</span>
                  <span>{payment.status}</span>
                  <span>${payment.amount.toFixed(2)}</span>
                </div>
              </li>
            ))
          ) : (
            <li>No payments found</li>
          )}
        </ul>
      </div>

      {/* Modal for showing filters */}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        filter={filter}
        onFilterChange={handleFilterChange}
        onApplyFilters={handleApplyFilters}
        onResetFilters={handleResetFilters}
      />
    </div>
  );
};

// Define PropTypes for the Modal component
SellerPayments.propTypes = {
  // Add your PropTypes here if needed
};

export default SellerPayments;
