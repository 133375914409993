import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { MdDeleteForever, MdEdit } from "react-icons/md";

Modal.setAppElement("#root");

const AddressCard = ({ setSelectedAddressID }) => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    houseNumber: "",
    buildingName: "",
    streetName: "",
    locality: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    isDefault: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const countryOptions = ["USA", "Canada", "UK", "Australia", "India"];

  useEffect(() => {
    fetchAddresses();
  }, [isModalOpen]);

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://lapkart1-1.onrender.com/api/addresses/address",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      const sortedAddresses = response.data.sort(
        (a, b) => b.isDefault - a.isDefault
      );
      setAddresses(sortedAddresses);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleAddOrUpdateAddress = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const endpoint = isEditing
      ? `https://lapkart1-1.onrender.com/api/addresses/${editingAddressId}`
      : "https://lapkart1-1.onrender.com/api/addresses";
    const method = isEditing ? "put" : "post";

    try {
      await axios[method](endpoint, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setIsModalOpen(false);
      setFormData({
        houseNumber: "",
        buildingName: "",
        streetName: "",
        locality: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        isDefault: false,
      });
      setIsEditing(false);
      setEditingAddressId(null);
      fetchAddresses(); // Refresh addresses after add/update
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditAddress = (address) => {
    setFormData({
      houseNumber: address.houseNumber || "",
      buildingName: address.buildingName || "",
      streetName: address.streetName || "",
      locality: address.locality || "",
      city: address.city || "",
      state: address.state || "",
      postalCode: address.postalCode || "",
      country: address.country || "",
      isDefault: address.isDefault || false,
    });
    setIsEditing(true);
    setEditingAddressId(address._id);
    setIsModalOpen(true);
  };

  const handleDeleteAddress = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`https://lapkart1-1.onrender.com/api/addresses/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setAddresses((prevAddresses) =>
        prevAddresses.filter((address) => address._id !== id)
      );
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSetDefaultAddress = async (id) => {
    setLoading(true);
    try {
      await axios.put(
        `https://lapkart1-1.onrender.com/api/addresses/set-default/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      fetchAddresses(); // Refresh addresses after setting default
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setError(null);
    setIsEditing(false);
    setEditingAddressId(null);
    setFormData({
      houseNumber: "",
      buildingName: "",
      streetName: "",
      locality: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      isDefault: false,
    });
  };

  const handleSelectAddress = (id) => {
    setSelectedAddressID(id); // Pass selected address ID to parent component
    setSelectedAddressId(id); // Set selected address ID for styling
  };

  return (
    <div>
      {loading && <div>Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      {addresses.map((address) => (
        <div
          key={address._id}
          className={`border p-4 rounded-lg shadow-sm mb-4 ${
            address._id === selectedAddressId
              ? "border-2 border-blue-500"
              : "border-gray-300"
          }`}
        >
          <div className="flex justify-between items-start">
            <div className="text-gray-700">
              {address.houseNumber && (
                <p className="font-semibold">
                  {address.houseNumber}, {address.buildingName},{" "}
                  {address.streetName}, {address.locality}
                </p>
              )}
              <p>
                {address.city}, {address.state} {address.postalCode}
              </p>
              <p>{address.country}</p>
              {address.isDefault && (
                <span className="text-green-500 font-bold">Default</span>
              )}
            </div>
            <div className="flex space-x-2">
              <MdEdit
                className="text-blue-500 cursor-pointer hover:text-blue-700"
                onClick={() => handleEditAddress(address)}
              />
              <MdDeleteForever
                className="text-red-500 cursor-pointer hover:text-red-700"
                onClick={() => handleDeleteAddress(address._id)}
              />
              <button
                className="text-blue-600 hover:text-blue-800 focus:outline-none"
                onClick={() => handleSelectAddress(address._id)}
              >
                Select Address
              </button>
            </div>
          </div>
        </div>
      ))}
      <button
        className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900"
        onClick={() => setIsModalOpen(true)}
      >
        Add Address
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        contentLabel="Address Modal"
        className="bg-white p-6 rounded shadow-lg mx-auto mt-10 max-w-full sm:max-w-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-2xl font-semibold mb-4">
          {isEditing ? "Edit Address" : "Add Address"}
        </h2>
        <form
          onSubmit={handleAddOrUpdateAddress}
          className="grid grid-cols-1 sm:grid-cols-2 gap-4"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="houseNumber"
            >
              House Number
            </label>
            <input
              id="houseNumber"
              name="houseNumber"
              type="text"
              value={formData.houseNumber}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="buildingName"
            >
              Building Name
            </label>
            <input
              id="buildingName"
              name="buildingName"
              type="text"
              value={formData.buildingName}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 col-span-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="streetName"
            >
              Street Name
            </label>
            <input
              id="streetName"
              name="streetName"
              type="text"
              value={formData.streetName}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 col-span-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="locality"
            >
              Locality
            </label>
            <input
              id="locality"
              name="locality"
              type="text"
              value={formData.locality}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="city"
            >
              City
            </label>
            <input
              id="city"
              name="city"
              type="text"
              value={formData.city}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="state"
            >
              State
            </label>
            <input
              id="state"
              name="state"
              type="text"
              value={formData.state}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="postalCode"
            >
              Postal Code
            </label>
            <input
              id="postalCode"
              name="postalCode"
              type="text"
              value={formData.postalCode}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="country"
            >
              Country
            </label>
            <select
              id="country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              {countryOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4 flex items-center">
            <input
              id="isDefault"
              name="isDefault"
              type="checkbox"
              checked={formData.isDefault}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label
              className="text-gray-700 text-sm font-bold"
              htmlFor="isDefault"
            >
              Set as default address
            </label>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={handleCancel}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {isEditing ? "Update Address" : "Add Address"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddressCard;
