import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ImageCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageError, setImageError] = useState(false);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    setImageError(false); // Reset the image error state when navigating
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    setImageError(false); // Reset the image error state when navigating
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="relative w-full h-48">
      <button
        onClick={handlePrevClick}
        className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 shadow-md"
      >
        <FaArrowLeft />
      </button>
      {imageError ? (
        <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
          <span className="text-gray-500">Image not available</span>
        </div>
      ) : (
        <img
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex}`}
          onError={handleImageError}
          className="w-full h-48 object-cover"
        />
      )}
      <button
        onClick={handleNextClick}
        className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 shadow-md"
      >
        <FaArrowRight />
      </button>
    </div>
  );
};

export default ImageCarousel;
