import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const SellerLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    console.log(isSidebarOpen);
    
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`flex-1 ${isSidebarOpen ? 'md:ml-64' : 'md:ml-0'} transition-all duration-200 ease-in-out overflow-y-auto`}>
        <Navbar toggleSidebar={toggleSidebar} />
        <main className="p-4">
          {children}
        </main>
      </div>
    </div>
  );
};

export default SellerLayout;
