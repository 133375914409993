import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Preloader from './Loader/Preloader';
import AddressCard from './AddressCard';
import { toast, ToastContainer } from "react-toastify";

const BuyNow = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAddressID, setSelectedAddressID] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('ONLINE');
  const [razorpayOrderId, setRazorpayOrderId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get('https://lapkart1-1.onrender.com/api/cart/cartItem', {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });
        setCartItems(response.data.items);
      } catch (error) {
        console.error('Error fetching cart items:', error);
        setError(error.response?.data?.message || 'Error fetching cart items. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleConfirmOrder = async () => {
    setLoading(true);
    setError(null);

    if (!selectedAddressID) {
      toast.error('Please select a shipping address.');
      setLoading(false);
      return;
    }

    const totalAmount = (calculateTotal() * 1.1).toFixed(2); // Amount in INR

    const orderData = {
      customerID: JSON.parse(localStorage.getItem('user'))._id,
      totalAmount,
      orderStatus: 'Pending',
      orderItems: cartItems.map((item) => ({
        productId: item.productId._id,
        quantity: item.quantity,
        price: item.productId.price,
      })),
      defaultAddressID: selectedAddressID,
      paymentMethod,
      paymentID: paymentMethod === 'ONLINE' ? razorpayOrderId : null // Add paymentID if paymentMethod is 'ONLINE'
    };

    try {
      const orderResponse = await axios.post('https://lapkart1-1.onrender.com/api/orders/add', orderData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });

      const { orderID } = orderResponse.data;

      if (paymentMethod === 'COD') {
        navigate('/order-confirmation', { state: { orderID } });
      } else {
        const razorpayOrderId = await createRazorpayOrder(totalAmount * 100);

        if (razorpayOrderId) {
          setRazorpayOrderId(razorpayOrderId);
          const paymentSuccessful = await initiateRazorpayPayment(razorpayOrderId, totalAmount, orderID);

          if (paymentSuccessful) {
            navigate('/order-confirmation', { state: { orderID } });
          } else {
            toast.error('Payment failed. Please try again.');
          }
        } else {
          toast.error('Error creating Razorpay order. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error confirming order:', error);
      toast.error('Error confirming order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const createRazorpayOrder = async (totalAmount) => {
    try {
      const receiptId = `receipt_${Date.now()}`;
      const response = await axios.post('https://lapkart1-1.onrender.com/api/payments/create-order', {
        amount: totalAmount,
        currency: 'INR',
        receipt: receiptId,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });

      return response.data.order_id;
    } catch (error) {
      console.error('Error creating Razorpay order:', error.response?.data || error.message);
      return null;
    }
  };

  const initiateRazorpayPayment = async (orderId, totalAmount, dbOrderId) => {
    return new Promise((resolve) => {
      const options = {
        key: 'rzp_test_NvFg6zPzqUithL', // Replace with your Razorpay key
        amount: totalAmount * 100,
        currency: 'INR',
        order_id: orderId,
        name: 'MyLapKart',
        image: 'http://localhost:3000/logo.png',
        handler: async (response) => {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
          try {
            const captureResponse = await axios.post('https://lapkart1-1.onrender.com/api/payments/paymentCapture', {
              razorpayOrderId: razorpay_order_id,
              razorpayPaymentId: razorpay_payment_id,
              razorpaySignature: razorpay_signature,
              amount: totalAmount * 100,
              orderId: dbOrderId
            }, {
              headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
            });

            resolve(captureResponse.data.status === 'ok');
          } catch (error) {
            console.error('Error capturing Razorpay payment:', error.response?.data || error.message);
            resolve(false);
          }
        },
        prefill: {
          name: JSON.parse(localStorage.getItem('user')).fullName,
          email: JSON.parse(localStorage.getItem('user')).email,
          contact: JSON.parse(localStorage.getItem('user')).phoneNumber,
        },
        theme: { color: '#3399cc' },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on('payment.error', (response) => {
        console.error('Razorpay payment error:', response.error);
        resolve(false);
      });
    });
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.productId.price * item.quantity, 0);
  };

  const handleRemoveItem = async (itemId) => {
    try {
      const response = await axios.delete(`https://lapkart1-1.onrender.com/api/cart/remove/${itemId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      setCartItems(response.data.items);
    } catch (error) {
      console.error('Error removing item:', error);
      toast.error('Error removing item. Please try again.');
    }
  };

  const handleIncreaseQuantity = async (itemId) => {
    try {
      await axios.post(`https://lapkart1-1.onrender.com/api/cart/increase/${itemId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item._id === itemId ? { ...item, quantity: item.quantity + 1 } : item
        )
      );
    } catch (error) {
      console.error('Error increasing quantity:', error);
      toast.error('Error increasing quantity. Please try again.');
    }
  };

  const handleDecreaseQuantity = async (itemId) => {
    try {
      await axios.post(`https://lapkart1-1.onrender.com/api/cart/decrease/${itemId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item._id === itemId && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
      );
    } catch (error) {
      console.error('Error decreasing quantity:', error);
      toast.error('Error decreasing quantity. Please try again.');
    }
  };

  if (loading) return <Preloader />;

  if (error) {
    return (
      <div className="text-red-500 p-4">
        {error}
        {error.includes('Payment failed') && (
          <button
            onClick={handleConfirmOrder}
            className="ml-4 px-2 py-1 bg-blue-500 text-white rounded"
          >
            Retry Payment
          </button>
        )}
      </div>
    );
  }

  const total = calculateTotal();

  return (
    <div className="bg-gray-50 min-h-screen p-8">
      <div className="container mx-auto max-w-6xl bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row">
          {/* Shipping Address */}
          <div className="w-full md:w-1/2 p-6 bg-gray-100 border-r border-gray-200">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Shipping Address</h2>
            {/* Replace with your dynamic address component */}
            <AddressCard selectedAddressID={selectedAddressID} setSelectedAddressID={setSelectedAddressID} />
          </div>

          {/* Order Summary */}
          <div className="w-full md:w-1/2 p-6">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Order Summary</h2>
            <div className="space-y-4">
              {cartItems.map((item) => (
                <div key={item._id} className="flex justify-between items-center p-4 bg-gray-50 border rounded-lg shadow-sm">
                  <div className="flex items-center space-x-4">
                    <img src={item.productId.image} alt={item.productId.title} className="w-16 h-16 object-cover rounded-lg" />
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800">{item.productId.title}</h3>
                      <p className="text-gray-600">Price: ₹{item.productId.price.toFixed(2)}</p>
                      <div className="flex items-center space-x-2 mt-2">
                        <button onClick={() => handleDecreaseQuantity(item._id)} className="bg-gray-300 p-1 rounded hover:bg-gray-400">
                          -
                        </button>
                        <span className="px-4">{item.quantity}</span>
                        <button onClick={() => handleIncreaseQuantity(item._id)} className="bg-gray-300 p-1 rounded hover:bg-gray-400">
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleRemoveItem(item._id)}
                    className="text-red-500 hover:text-red-600"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <div className="flex justify-between items-center p-4 bg-gray-100 border-t border-gray-200 mt-4">
                <h3 className="text-xl font-bold text-gray-800">Total</h3>
                <span className="text-xl font-bold text-blue-600">₹{total.toFixed(2)}</span>
              </div>
            </div>

            {/* Payment Method */}
            <div className="mt-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Payment Method</h2>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setPaymentMethod('ONLINE')}
                  className={`p-3 rounded-lg border border-blue-500 ${paymentMethod === 'ONLINE' ? 'bg-blue-500 text-white' : 'text-blue-500'}`}
                >
                  Online Payment
                </button>
                <button
                  onClick={() => setPaymentMethod('COD')}
                  className={`p-3 rounded-lg border border-blue-500 ${paymentMethod === 'COD' ? 'bg-blue-500 text-white' : 'text-blue-500'}`}
                >
                  Cash on Delivery
                </button>
              </div>
            </div>

            {/* Confirm Order Button */}
            <button
              onClick={handleConfirmOrder}
              className="mt-6 px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
            >
              Confirm Order
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BuyNow;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import Preloader from './Loader/Preloader';
// import AddressCard from './AddressCard';

// const BuyNow = () => {
//   const [cartItems, setCartItems] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedAddressID, setSelectedAddressID] = useState(null);
//   const [paymentMethod, setPaymentMethod] = useState('ONLINE');
//   const [razorpayOrderId, setRazorpayOrderId] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchCartItems = async () => {
//       try {
//         const response = await axios.get('https://lapkart1-1.onrender.com/api/cart/cartItem', {
//           headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
//         });
//         setCartItems(response.data.items);
//         console.log(response.data);
        
//       } catch (error) {
//         console.error('Error fetching cart items:', error);
//         setError(error.response?.data?.message || 'Error fetching cart items. Please try again later.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCartItems();
//     loadScript("https://checkout.razorpay.com/v1/checkout.js");
//   }, []);

//   const loadScript = (src) => {
//     return new Promise((resolve) => {
//       const script = document.createElement("script");
//       script.src = src;
//       script.onload = () => resolve(true);
//       script.onerror = () => resolve(false);
//       document.body.appendChild(script);
//     });
//   };

//   const handleConfirmOrder = async () => {
//     setLoading(true);
//     setError(null);

//     if (!selectedAddressID) {
//       setError('Please select a shipping address.');
//       setLoading(false);
//       return;
//     }

//     const totalAmount = calculateTotal() * 1.1; // Amount in INR

//     const orderData = {
//       customerID: JSON.parse(localStorage.getItem('user'))._id,
//       totalAmount,
//       orderStatus: 'Pending',
//       orderItems: cartItems.map((item) => ({
//         productId: item.productId._id,
//         quantity: item.quantity,
//         price: item.productId.price,
//       })),
//       defaultAddressID: selectedAddressID,
//       paymentMethod,
//       paymentID: paymentMethod === 'ONLINE' ? razorpayOrderId : null // Add paymentID if paymentMethod is 'ONLINE'
//     };

//     try {
//       const orderResponse = await axios.post('https://lapkart1-1.onrender.com/api/orders/add', orderData, {
//         headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
//       });

//       const { orderID } = orderResponse.data;

//       if (paymentMethod === 'COD') {
//         navigate('/order-confirmation', { state: { orderID } });
//       } else {
//         const razorpayOrderId = await createRazorpayOrder(totalAmount * 100);

//         if (razorpayOrderId) {
//           setRazorpayOrderId(razorpayOrderId);
//           const paymentSuccessful = await initiateRazorpayPayment(razorpayOrderId, totalAmount, orderID);

//           if (paymentSuccessful) {
//             navigate('/order-confirmation', { state: { orderID } });
//           } else {
//             setError('Payment failed. Please try again.');
//           }
//         } else {
//           setError('Error creating Razorpay order. Please try again.');
//         }
//       }
//     } catch (error) {
//       console.error('Error confirming order:', error);
//       setError('Error confirming order. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const createRazorpayOrder = async (totalAmount) => {
//     try {
//       const receiptId = `receipt_${Date.now()}`;
//       const response = await axios.post('https://lapkart1-1.onrender.com/api/payments/create-order', {
//         amount: totalAmount,
//         currency: 'INR',
//         receipt: receiptId,
//       }, {
//         headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
//       });

//       return response.data.order_id;
//     } catch (error) {
//       console.error('Error creating Razorpay order:', error.response?.data || error.message);
//       return null;
//     }
//   };

//   const initiateRazorpayPayment = async (orderId, totalAmount, dbOrderId) => {
//     return new Promise((resolve) => {
//       const options = {
//         key: 'rzp_test_NvFg6zPzqUithL', // Replace with your Razorpay key
//         amount: totalAmount * 100,
//         currency: 'INR',
//         order_id: orderId,
//         name: 'MyLapKart',
//         image: 'http://localhost:3000/logo.png',
//         handler: async (response) => {
//           const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
//           try {
//             const captureResponse = await axios.post('https://lapkart1-1.onrender.com/api/payments/paymentCapture', {
//               razorpayOrderId: razorpay_order_id,
//               razorpayPaymentId: razorpay_payment_id,
//               razorpaySignature: razorpay_signature,
//               amount: totalAmount * 100,
//               orderId: dbOrderId
//             }, {
//               headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
//             });

//             resolve(captureResponse.data.status === 'ok');
//           } catch (error) {
//             console.error('Error capturing Razorpay payment:', error.response?.data || error.message);
//             resolve(false);
//           }
//         },
//         prefill: {
//           name: JSON.parse(localStorage.getItem('user')).fullName,
//           email: JSON.parse(localStorage.getItem('user')).email,
//           contact: JSON.parse(localStorage.getItem('user')).phoneNumber,
//         },
//         theme: { color: '#3399cc' },
//       };

//       const paymentObject = new window.Razorpay(options);
//       paymentObject.open();

//       paymentObject.on('payment.error', (response) => {
//         console.error('Razorpay payment error:', response.error);
//         resolve(false);
//       });
//     });
//   };

//   const calculateTotal = () => {
//     return cartItems.reduce((total, item) => total + item.productId.price * item.quantity, 0);
//   };

//   const handleRemoveItem = async (itemId) => {
//     try {
//       const response = await axios.delete(`https://lapkart1-1.onrender.com/api/cart/remove/${itemId}`, {
//         headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
//       });
//       setCartItems(response.data.items);
//     } catch (error) {
//       console.error('Error removing item:', error);
//       setError('Error removing item. Please try again.');
//     }
//   };

//   const handleIncreaseQuantity = async (itemId) => {
//     try {
//       await axios.post(`https://lapkart1-1.onrender.com/api/cart/increase/${itemId}`, {}, {
//         headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
//       });
//       setCartItems((prevItems) =>
//         prevItems.map((item) =>
//           item._id === itemId ? { ...item, quantity: item.quantity + 1 } : item
//         )
//       );
//     } catch (error) {
//       console.error('Error increasing quantity:', error);
//       setError('Error increasing quantity. Please try again.');
//     }
//   };

//   const handleDecreaseQuantity = async (itemId) => {
//     try {
//       await axios.post(`https://lapkart1-1.onrender.com/api/cart/decrease/${itemId}`, {}, {
//         headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
//       });
//       setCartItems((prevItems) =>
//         prevItems.map((item) =>
//           item._id === itemId && item.quantity > 1
//             ? { ...item, quantity: item.quantity - 1 }
//             : item
//         )
//       );
//     } catch (error) {
//       console.error('Error decreasing quantity:', error);
//       setError('Error decreasing quantity. Please try again.');
//     }
//   };

//   if (loading) return <Preloader />;

//   if (error) {
//     return (
//       <div className="text-red-500 p-4">
//         {error}
//         {error.includes('Payment failed') && (
//           <button
//             onClick={handleConfirmOrder}
//             className="ml-4 px-2 py-1 bg-blue-500 text-white rounded"
//           >
//             Retry Payment
//           </button>
//         )}
//       </div>
//     );
//   }

//   const total = calculateTotal();

//   return (
//     <div className="font-sans bg-white p-4 lg:max-w-7xl max-w-4xl mx-auto">
//       <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
//         <div>
//           <h2 className="text-xl font-semibold text-gray-900 mb-4">Shipping Address</h2>
//           <AddressCard setSelectedAddressID={setSelectedAddressID} />
//         </div>
//         <div>
//           <h2 className="text-xl font-semibold text-gray-900 mb-4">Order Info</h2>
//           {cartItems.map((item) => (
//             <div
//               key={item.productId._id}
//               className="flex items-center justify-between border-b border-gray-200 py-4"
//             >
//               <div className="flex items-center">
//                 <img
//                   src={item.productId.images[0]}
//                   alt={item.productId.title}
//                   className="w-20 h-20 object-cover mr-4"
//                 />
//                 <div>
//                   <h3 className="text-lg font-medium text-gray-900">{item.productId.title}</h3>
//                   <p className="text-sm text-gray-600">₹{item.productId.price}</p>
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <button
//                   onClick={() => handleDecreaseQuantity(item._id)}
//                   className="px-2 py-1 bg-gray-300 rounded"
//                 >
//                   -
//                 </button>
//                 <span className="mx-2">{item.quantity}</span>
//                 <button
//                   onClick={() => handleIncreaseQuantity(item._id)}
//                   className="px-2 py-1 bg-gray-300 rounded"
//                 >
//                   +
//                 </button>
//                 <button
//                   onClick={() => handleRemoveItem(item._id)}
//                   className="ml-4 px-2 py-1 bg-red-500 text-white rounded"
//                 >
//                   Remove
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="flex justify-between items-center mt-4">
//         <div>
//           <h2 className="text-xl font-semibold text-gray-900">Total: ₹{total}</h2>
//           <select
//             value={paymentMethod}
//             onChange={(e) => setPaymentMethod(e.target.value)}
//             className="mt-2 border rounded"
//           >
//             <option value="ONLINE">Online Payment</option>
//             <option value="COD">Cash on Delivery</option>
//           </select>
//         </div>
//         <button
//           onClick={handleConfirmOrder}
//           className="px-4 py-2 bg-blue-500 text-white rounded"
//         >
//           Confirm Order
//         </button>
//       </div>
//     </div>
//   );
// };

// export default BuyNow;