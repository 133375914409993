import React from "react";

const ContactUs = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center mb-8">Contact Us</h2>
        <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md">
          <p className="mb-4">We’re here to help! Whether you have a question about our products, an order, or just want to provide feedback, we’re eager to hear from you. Please choose the most convenient method below to get in touch with us.</p>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Customer Service</h3>
            <p>Email: <a href="mailto:support@mylapkart.com" className="text-blue-600">support@mylapkart.com</a></p>
            <p>Phone: +91 72196 55222</p>
            <p>Hours: Monday to Friday, 9:00 AM - 6:00 PM (EST)</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
