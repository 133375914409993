import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { RxCountdownTimer } from "react-icons/rx";
import { RiBox3Fill } from "react-icons/ri";
import { BsGraphUpArrow } from "react-icons/bs";
import SalesDetailsChart from './SalesDetailsChart';
import MonthlySalesSummary from './MonthlySalesSummary';
import { enUS } from 'date-fns/locale';

const SellerDashboard = () => {
  const [stats, setStats] = useState([
    {
      title: "Total Users",
      value: "",
      percentage: "",
      trend: "",
      trendColor: "",
      icon: <FaUserFriends className="text-[#8280FF] text-2xl md:text-3xl" />,
      bgColor: "bg-[#e4e4fe]",
    },
    {
      title: "Total Orders",
      value: "",
      percentage: "",
      trend: "",
      trendColor: "",
      icon: <RiBox3Fill className="text-[#ffc53d] text-2xl md:text-3xl" />,
      bgColor: "bg-[#fef3d5]",
    },
    {
      title: "Total Sales",
      value: "$0",
      percentage: "0%",
      trend: "up",
      trendColor: "text-green-600",
      icon: <BsGraphUpArrow className="text-[#49d991] text-2xl md:text-3xl" />,
      bgColor: "bg-[#d8f7e8]",
    },
    {
      title: "Total Pending",
      value: "0",
      percentage: "0%",
      trend: "up",
      trendColor: "text-green-600",
      icon: <RxCountdownTimer className="text-[#FF9066] text-2xl md:text-3xl" />,
      bgColor: "bg-[#ffddd1]",
    }
  ]);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Orders',
        data: [],
        fill: true,
        backgroundColor: 'rgba(73, 217, 145, 0.5)',
        borderColor: '#49d991',
        borderWidth: 2,
      }
    ]
  });

  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `Orders: ${context.raw}`;
          }
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'PP',
          displayFormats: {
            day: 'MMM d',
          },
          adapter: {
            locale: enUS,
          },
        },
      },
      y: {
        beginAtZero: true,
      }
    }
  });

  const [summary, setSummary] = useState({
    totalSales: 0,
    totalOrders: 0,
    averageOrderValue: 0,
  });

  const [latestOrders, setLatestOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const headers = { Authorization: `Bearer ${token}` };
    
        const [usersResponse, ordersResponse] = await Promise.all([
          axios.get('https://lapkart1-1.onrender.com/api/users', { headers }),
          axios.get('https://lapkart1-1.onrender.com/api/orders/getAllOrders', { headers })
        ]);
    
        const totalUsers = usersResponse.data.length || 0;
        const totalOrders = ordersResponse.data.length || 0;
        const totalAmount = ordersResponse.data.reduce((acc, order) => acc + (order.totalAmount || 0), 0).toFixed(2);
        const totalPending = ordersResponse.data.filter(order => order.orderStatus === "Pending").length || 0;
    
        // Aggregate orders by day
        const dailyOrders = {};
        ordersResponse.data.forEach(order => {
          const date = new Date(order.createdAt).toLocaleDateString();
          if (!dailyOrders[date]) {
            dailyOrders[date] = 0;
          }
          dailyOrders[date] += 1; // Count orders
        });
    
        const labels = Object.keys(dailyOrders).sort((a, b) => new Date(a) - new Date(b));
        const data = labels.map(label => dailyOrders[label]);
    
        const chartData = {
          labels,
          datasets: [
            {
              label: 'Total Orders',
              data,
              fill: true,
              backgroundColor: 'rgba(73, 217, 145, 0.5)',
              borderColor: '#49d991',
              borderWidth: 2,
            },
          ],
        };
    
        const averageOrderValue = (totalAmount / totalOrders).toFixed(2);
    
        if (Array.isArray(ordersResponse.data)) {
          // Sort orders by createdAt in descending order and get the latest 5 orders
          const latestOrdersData = ordersResponse.data
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 5);
    
          setLatestOrders(latestOrdersData);
        } else {
          console.error('Error: ordersResponse is not an array');
        }
    
        setStats(prevStats => [
          {
            ...prevStats[0],
            value: totalUsers.toString(),
            percentage: "8.5%",
            trend: "up",
            trendColor: "text-green-600",
          },
          {
            ...prevStats[1],
            value: totalOrders.toString(),
            percentage: "1.3%",
            trend: "up",
            trendColor: "text-green-600",
          },
          {
            ...prevStats[2],
            value: `₹${totalAmount}`,
            percentage: "4.3%",
            trend: "down",
            trendColor: "text-red-600",
          },
          {
            ...prevStats[3],
            value: totalPending.toString(),
            percentage: "1.8%",
            trend: "up",
            trendColor: "text-green-600",
          }
        ]);
    
        setChartData(chartData);
        setSummary({
          totalSales: totalAmount,
          totalOrders: totalOrders,
          averageOrderValue: averageOrderValue
        });
    
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    

    fetchData();
  }, []);

  return (
    <section className="flex-grow p-4 overflow-auto">
      <h3 className="text-2xl md:text-3xl lg:text-4xl text-[#202224] font-bold mb-4">
        Dashboard
      </h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {stats.map((stat, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-4 flex flex-col justify-between">
            <div className="flex justify-between items-center">
              <h4 className="text-sm md:text-base lg:text-lg font-bold text-gray-700">
                {stat.title}
              </h4>
              <div className={`${stat.bgColor} p-3 rounded-md`}>{stat.icon}</div>
            </div>
            <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold my-4">{stat.value}</h3>
            <div className={`flex items-center ${stat.trendColor}`}>
              {stat.trend === 'up' ? (
                <FaArrowTrendUp className="w-6 h-6 mr-1" />
              ) : (
                <FaArrowTrendDown className="w-6 h-6 mr-1" />
              )}
              <span className="text-base">{stat.percentage}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
        <div className="bg-white rounded-lg shadow-md p-4">
          <MonthlySalesSummary
            totalSales={summary.totalSales}
            totalOrders={summary.totalOrders}
            averageOrderValue={summary.averageOrderValue}
          />
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h4 className="text-lg font-bold text-gray-800 mb-4">Latest 5 Orders</h4>
          <div>
            {latestOrders && latestOrders.length > 0 ? (
              latestOrders.map((order) => (
                <div key={order._id} className="border-b border-gray-200 py-3 flex justify-between">
                  <div className="flex-1">
                    <p className="text-gray-700 font-semibold">Order ID: {order.orderID || 'N/A'}</p>
                    <p className="text-gray-500">Date: {order.createdAt ? new Date(order.createdAt).toLocaleDateString() : 'N/A'}</p>
                    <p className="text-gray-500">Total: ₹{order.totalAmount ? order.totalAmount.toFixed(2) : '0.00'}</p>
                  </div>
                  <div className="flex-none">
                    <span className={`px-2 py-1 text-xs font-semibold rounded ${order.orderStatus === 'Completed' ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}>
                      {order.orderStatus || 'Unknown'}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No orders available.</p>
            )}
          </div>
        </div>
      </div>

      <SalesDetailsChart data={chartData} options={chartOptions} />
    </section>
  );
};

export default SellerDashboard;
