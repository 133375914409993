import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2
import { MdEditDocument, MdOutlineAutoDelete } from "react-icons/md";
// Initialize SweetAlert2 for React

const Category = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null); // For edit functionality

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://lapkart1-1.onrender.com/api/categories");
      setCategories(response.data);
    } catch (error) {
      Swal.fire("Error", "Error fetching categories. Please try again.", "error");
      console.error("Error fetching categories:", error);
    }
  };

  const openCategoryModal = (category = null) => {
    setIsModalOpen(true);
    setEditingCategory(category); // Set category for editing or null for adding
    setNewCategory(category ? category.name : ""); // Pre-fill with the category name if editing
  };

  const closeCategoryModal = () => {
    setIsModalOpen(false);
    setNewCategory("");
    setEditingCategory(null);
  };

  const handleCategoryChange = (e) => {
    setNewCategory(e.target.value);
  };

  const addOrEditCategory = async (e) => {
    e.preventDefault();
    try {
      if (editingCategory) {
        // Edit existing category
        await axios.put(`https://lapkart1-1.onrender.com/api/categories/${editingCategory._id}`, {
          name: newCategory,
        });
        Swal.fire("Success", "Category updated successfully!", "success");
      } else {
        // Add new category
        await axios.post("https://lapkart1-1.onrender.com/api/categories", {
          name: newCategory,
        });
        Swal.fire("Success", "Category added successfully!", "success");
      }
      setNewCategory("");
      fetchCategories();
      closeCategoryModal();
    } catch (error) {
      Swal.fire("Error", "Error saving category. Please try again.", "error");
      console.error("Error saving category:", error);
    }
  };

  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://lapkart1-1.onrender.com/api/categories/${categoryId}`);
          Swal.fire("Deleted!", "Category has been deleted.", "success");
          fetchCategories();
        } catch (error) {
          Swal.fire("Error", "Error deleting category. Please try again.", "error");
          console.error("Error deleting category:", error);
        }
      }
    });
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Categories</h1>
        <button
          onClick={() => openCategoryModal()}
          className="px-4 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Add Category
        </button>
      </div>

      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Category ID</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Category Name</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category._id} className="border-t border-gray-300">
              <td className="py-3 px-4 text-sm text-gray-700">{category._id}</td>
              <td className="py-3 px-4 text-sm text-gray-700">{category.name}</td>
              <td className="py-3 px-4 text-sm text-gray-700">
              <div className="flex space-x-2"> {/* Container holding the buttons */}
  <button
    onClick={() => openCategoryModal(category)}
    className="flex items-center space-x-1 px-3 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
  >
    <MdEditDocument />
    <span>Edit</span>
  </button>

  <button
    onClick={() => deleteCategory(category._id)}
    className="flex items-center space-x-1 px-3 py-1 bg-red-600 text-white rounded-md hover:bg-red-700"
  >
    <MdOutlineAutoDelete />
    <span>Delete</span>
  </button>
</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeCategoryModal}
        contentLabel={editingCategory ? "Edit Category" : "Add Category"}
        className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
      >
        <h2 className="text-xl font-semibold mb-4">
          {editingCategory ? "Edit Category" : "Add New Category"}
        </h2>
        <form onSubmit={addOrEditCategory}>
          <div>
            <label className="block text-sm font-medium text-gray-700">Category Name</label>
            <input
              type="text"
              value={newCategory}
              onChange={handleCategoryChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              required
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={closeCategoryModal}
              className="px-4 py-2 bg-gray-600 text-white rounded-md shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 text-white rounded-md shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              {editingCategory ? "Update Category" : "Add Category"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Category;
