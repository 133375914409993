import React from 'react';
import { FaTruck, FaHeadset, FaCreditCard, FaUndo } from 'react-icons/fa';

const shippingInfo = [
  {
    icon: <FaTruck className="text-blue-800 text-4xl" />, // Adjusted color
    title: 'Free Shipping',
    description: 'On orders over ₹ 99',
    iconBgColor: 'bg-blue-200',
  },
  {
    icon: <FaHeadset className="text-green-800 text-4xl" />, // Adjusted color
    title: '24/7 Support',
    description: 'Live chat or call',
    iconBgColor: 'bg-green-200',
  },
  {
    icon: <FaCreditCard className="text-yellow-800 text-4xl" />, // Adjusted color
    title: 'Online Payment',
    description: 'Secure payment services',
    iconBgColor: 'bg-yellow-200',
  },
  {
    icon: <FaUndo className="text-purple-800 text-4xl" />, // Adjusted color
    title: 'Easy Return',
    description: 'Hassle-free shopping',
    iconBgColor: 'bg-purple-200',
  },
];

const ShippingInfo = () => {
  return (
    <section className="bg-white py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-semibold mb-8 text-center">Shipping Information</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          {shippingInfo.map((item, index) => (
            <div key={index} className="bg-gray-100 rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
              <div className={`flex items-center justify-center h-24 ${item.iconBgColor}`}>
                {item.icon}
              </div>
              <div className="p-4 text-center">
                <h3 className="text-lg font-semibold text-gray-800">{item.title}</h3>
                <p className="text-sm text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ShippingInfo;
