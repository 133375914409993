import React from "react";

const CancellationAndRefundPolicy = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center mb-8">Cancellation and Refund Policy</h2>
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">Introduction</h3>
          <p className="mb-4">At Mylapkart we aim to provide a smooth and satisfactory shopping experience. This Cancellation and Refund Policy outlines the terms and conditions for cancelling orders and requesting refunds.</p>
          <h3 className="text-lg font-semibold mb-4">Order Cancellation</h3>
          <p className="mb-4">Before Shipment: You can cancel your order for any reason before it has been shipped. To cancel your order please contact our customer service team as soon as possible at support@mylapkart.com or call +91 72196 55222. Once the cancellation is confirmed you will receive a full refund.</p>
          <p className="mb-4">After Shipment: If your order has already been shipped you will need to follow our return process to obtain a refund.</p>
          <h3 className="text-lg font-semibold mb-4">Return Policy</h3>
          <p className="mb-4">Eligibility: You can return most items within 7 days of receipt for a full refund or exchange provided the items are in their original condition unused and with all original packaging and tags attached. Certain items such as perishable goods custom-made products and personal care items may not be eligible for return. Please refer to the product page for specific return eligibility.</p>
          <p className="mb-4">Return Process:
            <ol className="list-decimal list-inside">
              <li>Contact our customer service team at support@mylapkart.com or call +91 72196 55222 to initiate a return.</li>
              <li>Our team will provide you with a Return Merchandise Authorization (RMA) number and instructions on how to return your item.</li>
              <li>Pack the item securely in its original packaging and include the RMA number and a copy of the receipt.</li>
              <li>Ship the item to the address provided by our customer service team. You are responsible for return shipping costs unless the return is due to a defect or error on our part.</li>
            </ol>
          </p>
          <h3 className="text-lg font-semibold mb-4">Refund Policy</h3>
          <p className="mb-4">Processing Time: Once we receive your returned item we will inspect it and notify you of the approval or rejection of your refund. If approved your refund will be processed within 7-14 business days and a credit will be applied to your original method of payment.</p>
          <p className="mb-4">Partial Refunds: In certain situations only partial refunds may be granted (e.g. items with obvious signs of use missing parts or not in original condition).</p>
          <p className="mb-4">Shipping Costs: Original shipping costs are non-refundable unless the return is due to a defect or error on our part. If you receive a refund the cost of return shipping will be deducted from your refund unless the return is due to a defect or error on our part.</p>
          <h3 className="text-lg font-semibold mb-4">Exchanges</h3>
          <p className="mb-4">Eligibility: We only replace items if they are defective or damaged. If you need to exchange an item for the same product contact our customer service team at support@mylapkart.com or call +91 7219655222.</p>
          <p className="mb-4">Process: Follow the return process outlined above to send the defective or damaged item back to us. Once received and inspected we will send you a replacement item at no additional cost.</p>
          <h3 className="text-lg font-semibold mb-4">Non-Returnable Items</h3>
          <p className="mb-4">Certain items are not eligible for return or refund including:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Damaged goods after delivery (e.g. damaged by receiver intentionally damaged with no proof of already damaged or broken before delivery)</li>
            <li>On Sale Products or Promotional sales products</li>
            <li>Downloadable software or digital products</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">Contact Information</h3>
          <p className="mb-4">If you have any questions or need assistance with a cancellation return or refund please contact us at: 
            <ul className="list-disc list-inside">
              <li>Email: support@mylapkart.com</li>
              {/* <li>Phone: +91 72196 55222</li> */}
              <li>Mailing Address: Mylapkart Shop No. 26 First Floor Navkar Plaza Waki Road Jamner Tal-Jamner Dist-Jalgaon 424206 (IN-MH)</li>
            </ul>
          </p>
        </div>
      </div>
    </section>
  );
};

export default CancellationAndRefundPolicy;
