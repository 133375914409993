import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaBars, FaUserCircle, FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const Navbar = ({ toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [user, setUser] = useState(null);

  
  console.log(toggleSidebar);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get('https://lapkart1-1.onrender.com/api/sellers/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error.response?.data?.message || 'An unexpected error occurred');
      }
    };

    fetchUserData();
  }, []);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <nav className="flex justify-between items-center p-4 bg-white text-gray-800 shadow-md">
      {/* Left side */}
      <div className="flex items-center space-x-4">
        <FaBars className="text-xl text-[#202224] cursor-pointer" onClick={toggleSidebar} />
        
      </div>

      {/* Right side */}
      <div className="flex items-center space-x-4 sm:space-x-6">
        <div className="relative">
          <div className="flex items-center space-x-2 cursor-pointer" onClick={toggleDropdown}>
            <FaUserCircle className="text-2xl text-gray-800" />
            <div className="hidden sm:block">
              {user ? (
                <>
                  <span className="font-bold">{user.sellerName}</span>
                  <span className="block text-sm text-gray-500">{user.role || 'Seller'}</span>
                </>
              ) : (
                <span className="font-bold">Loading...</span>
              )}
            </div>
            <FaChevronDown className="text-gray-800 hidden sm:block" />
          </div>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white text-gray-800 rounded-lg shadow-lg z-10">
              <Link to="/settings" className="block px-4 py-2 hover:bg-gray-200">Settings</Link>
              <Link to="/login" className="block px-4 py-2 hover:bg-gray-200">Logout</Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
