import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UploadProduct = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [stock, setStock] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [warrantyInformation, setWarrantyInformation] = useState('');
  const [shippingInformation, setShippingInformation] = useState('');
  const [returnPolicy, setReturnPolicy] = useState('');
  const [condition, setCondition] = useState('');
  const [images, setImages] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const [specKey, setSpecKey] = useState('');
  const [specValue, setSpecValue] = useState('');
  const [message, setMessage] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://lapkart1-1.onrender.com/api/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error.message);
      }
    };
    fetchCategories();
  }, []);

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleAddSpecification = () => {
    if (specKey && specValue) {
      setSpecifications([...specifications, { key: specKey, value: specValue }]);
      setSpecKey('');
      setSpecValue('');
    }
  };

  const handleRemoveSpecification = (index) => {
    setSpecifications(specifications.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('price', price);
    formData.append('discountPercentage', discountPercentage);
    formData.append('stock', stock);
    formData.append('brand', brand);
    formData.append('model', model);
    formData.append('warrantyInformation', warrantyInformation);
    formData.append('shippingInformation', shippingInformation);
    formData.append('returnPolicy', returnPolicy);
    formData.append('condition', condition);
    images.forEach((image) => formData.append('images', image));
    formData.append('specifications', JSON.stringify(specifications));

    try {
      const response = await axios.post('https://lapkart1-1.onrender.com/api/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Product uploaded successfully');
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading product:', error.message);
      setMessage(`Error uploading product: ${error.message}`);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Upload Product</h2>
        {message && <p className={`mb-4 text-center ${message.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>{message}</p>}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Category</label>
          <select
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>{category.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Price</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Discount Percentage</label>
          <input
            type="number"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Stock</label>
          <input
            type="number"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Brand</label>
          <input
            type="text"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Model</label>
          <input
            type="text"
            value={model}
            onChange={(e) => setModel(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Warranty Information</label>
          <input
            type="text"
            value={warrantyInformation}
            onChange={(e) => setWarrantyInformation(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Shipping Information</label>
          <input
            type="text"
            value={shippingInformation}
            onChange={(e) => setShippingInformation(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Return Policy</label>
          <input
            type="text"
            value={returnPolicy}
            onChange={(e) => setReturnPolicy(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Condition</label>
          <input
            type="text"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Specifications</label>
          <div className="flex items-center mb-2">
            <input
              type="text"
              placeholder="Key"
              value={specKey}
              onChange={(e) => setSpecKey(e.target.value)}
              className="w-1/2 px-4 py-2 border rounded-md mr-2"
            />
            <input
              type="text"
              placeholder="Value"
              value={specValue}
              onChange={(e) => setSpecValue(e.target.value)}
              className="w-1/2 px-4 py-2 border rounded-md"
            />
            <button
              type="button"
              onClick={handleAddSpecification}
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add
            </button>
          </div>
          <ul>
            {specifications.map((spec, index) => (
              <li key={index} className="flex items-center justify-between mb-1">
                <span>{spec.key}: {spec.value}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveSpecification(index)}
                  className="px-2 py-1 bg-red-500 text-white rounded-md"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Images</label>
          <input
            type="file"
            onChange={handleImageChange}
            multiple
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <button type="submit" className="w-full px-4 py-2 bg-green-500 text-white rounded-md">Upload Product</button>
      </form>
    </div>
  );
};

export default UploadProduct;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const UploadProduct = () => {
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [category, setCategory] = useState('');
//   const [price, setPrice] = useState('');
//   const [discountPercentage, setDiscountPercentage] = useState('');
//   const [stock, setStock] = useState('');
//   const [brand, setBrand] = useState('');
//   const [model, setModel] = useState('');
//   const [warrantyInformation, setWarrantyInformation] = useState('');
//   const [shippingInformation, setShippingInformation] = useState('');
//   const [returnPolicy, setReturnPolicy] = useState('');
//   const [condition, setCondition] = useState('');
//   const [images, setImages] = useState([]);
//   const [specifications, setSpecifications] = useState([]);
//   const [specKey, setSpecKey] = useState('');
//   const [specValue, setSpecValue] = useState('');
//   const [message, setMessage] = useState('');
//   const [categories, setCategories] = useState([]);

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleAddSpecification = () => {
//     if (specKey && specValue) {
//       setSpecifications([...specifications, { key: specKey, value: specValue }]);
//       setSpecKey('');
//       setSpecValue('');
//     }
//   };

//   const handleRemoveSpecification = (index) => {
//     setSpecifications(specifications.filter((_, i) => i !== index));
//   };

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get('https://lapkart1-1.onrender.com/api/categories');
//         setCategories(response.data);
//       } catch (error) {
//         console.error('Error fetching categories:', error.message);
//       }
//     };
//     fetchCategories();
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('description', description);
//     formData.append('category', category);
//     formData.append('price', price);
//     formData.append('discountPercentage', discountPercentage);
//     formData.append('stock', stock);
//     formData.append('brand', brand);
//     formData.append('model', model);
//     formData.append('warrantyInformation', warrantyInformation);
//     formData.append('shippingInformation', shippingInformation);
//     formData.append('returnPolicy', returnPolicy);
//     formData.append('condition', condition);
//     images.forEach((image) => formData.append('images', image));

//     formData.append('specifications', JSON.stringify(specifications));

//     try {
//       const response = await axios.post('https://lapkart1-1.onrender.com/api/products', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       setMessage('Product uploaded successfully');
//       console.log(response.data);
//     } catch (error) {
//       console.error('Error uploading product:', error.message);
//       setMessage(`Error uploading product: ${error.message}`);
//     }
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 rounded shadow-md">
//         <h2 className="text-2xl font-semibold mb-4">Upload Product</h2>
//         {message && <p className={`mb-4 text-center ${message.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>{message}</p>}
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Title</label>
//           <input
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Description</label>
//           <textarea
//             value={description}
//             onChange={(e) => setDescription(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Category</label>
//           <select
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
//             value={category}
//             onChange={(e) => setCategory(e.target.value)}
//             required
//           >
//             <option value="">Select a category</option>
//             {categories.map((category) => (
//               <option key={category._id} value={category._id}>{category.name}</option>
//             ))}
//           </select>
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Price</label>
//           <input
//             type="number"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Discount Percentage</label>
//           <input
//             type="number"
//             value={discountPercentage}
//             onChange={(e) => setDiscountPercentage(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Stock</label>
//           <input
//             type="number"
//             value={stock}
//             onChange={(e) => setStock(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Brand</label>
//           <input
//             type="text"
//             value={brand}
//             onChange={(e) => setBrand(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Model</label>
//           <input
//             type="text"
//             value={model}
//             onChange={(e) => setModel(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Warranty Information</label>
//           <input
//             type="text"
//             value={warrantyInformation}
//             onChange={(e) => setWarrantyInformation(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Shipping Information</label>
//           <input
//             type="text"
//             value={shippingInformation}
//             onChange={(e) => setShippingInformation(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Return Policy</label>
//           <input
//             type="text"
//             value={returnPolicy}
//             onChange={(e) => setReturnPolicy(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Condition</label>
//           <input
//             type="text"
//             value={condition}
//             onChange={(e) => setCondition(e.target.value)}
//             className="w-full px-4 py-2 border rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Specifications</label>
//           <div className="flex items-center mb-2">
//             <input
//               type="text"
//               placeholder="Key"
//               value={specKey}
//               onChange={(e) => setSpecKey(e.target.value)}
//               className="w-1/2 px-4 py-2 border rounded-md mr-2"
//             />
//             <input
//               type="text"
//               placeholder="Value"
//               value={specValue}
//               onChange={(e) => setSpecValue(e.target.value)}
//               className="w-1/2 px-4 py-2 border rounded-md"
//             />
//             <button
//               type="button"
//               onClick={handleAddSpecification}
//               className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md"
//             >
//               Add
//             </button>
//           </div>
//           <ul>
//             {specifications.map((spec, index) => (
//               <li key={index} className="flex items-center mb-2">
//                 <span className="w-1/2 px-4 py-2 border rounded-md mr-2">{spec.key}</span>
//                 <span className="w-1/2 px-4 py-2 border rounded-md">{spec.value}</span>
//                 <button
//                   type="button"
//                   onClick={() => handleRemoveSpecification(index)}
//                   className="ml-2 px-4 py-2 bg-red-500 text-white rounded-md"
//                 >
//                   Remove
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 mb-2">Images</label>
//           <input
//             type="file"
//             multiple
//             onChange={handleImageChange}
//             className="w-full px-4 py-2 border rounded-md"
//           />
//         </div>
//         <div className="flex justify-center">
//           <button
//             type="submit"
//             className="px-4 py-2 bg-green-500 text-white rounded-md"
//           >
//             Upload Product
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default UploadProduct;
