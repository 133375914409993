import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

const AddSlide = () => {
  const [slides, setSlides] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newSlide, setNewSlide] = useState({
    title: '',
    subtitle: '',
    description: '',
    priceLabel: '',
    price: '',
    buttonText: '',
    link: '',
    image: null,
  });

  useEffect(() => {
    fetchSlides();
  }, []);

  const fetchSlides = async () => {
    try {
      const response = await axios.get('https://lapkart1-1.onrender.com/api/sellers/slides');
      setSlides(response.data);
    } catch (error) {
      toast.error('Failed to fetch slides');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSlide({ ...newSlide, [name]: value });
  };

  const handleFileChange = (e) => {
    setNewSlide({ ...newSlide, image: e.target.files[0] });
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.keys(newSlide).forEach((key) => formData.append(key, newSlide[key]));

    try {
      await axios.post('https://lapkart1-1.onrender.com/api/sellers/slides', formData);
      toast.success('Slide added successfully');
      fetchSlides(); // Refresh slides list
      closeModal();
    } catch (error) {
      toast.error('Failed to add slide');
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://lapkart1-1.onrender.com/api/sellers/slides/${id}`);
          toast.success('Slide deleted successfully');
          fetchSlides(); // Refresh slides list
        } catch (error) {
          toast.error('Failed to delete slide');
        }
      }
    });
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%', // Adjust width as needed
      maxWidth: '600px', // Maximum width for larger screens
    },
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold">Slides</h1>
        <button
          onClick={openModal}
          className="px-4 py-2 bg-blue-500 text-white font-medium rounded hover:bg-blue-600"
        >
          Add Slide
        </button>
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <h2 className="text-lg font-semibold mb-4">Add New Slide</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="title"
            placeholder="Title"
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
          <input
            type="text"
            name="subtitle"
            placeholder="Subtitle"
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
          />
          <input
            type="text"
            name="description"
            placeholder="Description"
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
          />
          <input
            type="text"
            name="priceLabel"
            placeholder="Price Label"
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
          />
          <input
            type="text"
            name="price"
            placeholder="Price"
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
          />
          <input
            type="text"
            name="buttonText"
            placeholder="Button Text"
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
          />
          <input
            type="text"
            name="link"
            placeholder="Link"
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
          />
          <input
            type="file"
            name="image"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded"
          />
          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white font-medium rounded hover:bg-green-600"
            >
              Submit
            </button>
            <button
              onClick={closeModal}
              className="px-4 py-2 bg-gray-300 text-gray-700 font-medium rounded hover:bg-gray-400"
            >
              Close
            </button>
          </div>
        </form>
      </Modal>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Image
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {slides.map((slide) => (
              <tr key={slide._id}>
                <td className="px-6 py-4">
                  <img
                    src={slide.image}
                    alt={slide.title}
                    className="w-16 h-16 object-cover border-2 rounded"
                  />
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm font-medium text-gray-900">{slide.title}</div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-500">{slide.description}</div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-500">{slide.price}</div>
                </td>
                <td className="px-6 py-4 text-right text-gray-500">
                  <button
                    onClick={() => handleDelete(slide._id)}
                    className="px-4 py-2 bg-red-500 text-white font-medium rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                  {/* Add Edit Button and Logic Here */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddSlide;
