import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SellerLogin = () => {
  const [sellerEmail, setEmail] = useState('');
  const [sellerPassword, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!sellerEmail) {
      newErrors.sellerEmail = 'Email is required';
    }
    if (!sellerPassword) {
      newErrors.sellerPassword = 'Password is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post('https://lapkart1-1.onrender.com/api/sellers/login', {
          sellerEmail,
          sellerPassword,
        });

        const { token } = response.data;
        if (token) {
          Cookies.set('token', token, { expires: 7, path: '/' });
          toast.success('Login successful!');
          setTimeout(() => navigate('/seller/dashboard'), 2000);
        } else {
          toast.error('Failed to retrieve token.');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Invalid email or password';
        setErrors((prevErrors) => ({ ...prevErrors, server: errorMessage }));
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section className="bg-[#568AFF] h-screen flex items-center justify-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 w-[500px]">
        <div className="mx-auto max-w-screen-sm text-center bg-white p-8 rounded-lg shadow-md">
          <h1 className="text-2xl text-[#202224] font-bold mb-6 text-center">Login to Account</h1>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-[#202224] mb-2 text-left">Email address:</label>
              <input
                type="email"
                id="email"
                className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.sellerEmail ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-600`}
                placeholder="demo@gmail.com"
                value={sellerEmail}
                onChange={({ target: { value } }) => setEmail(value)}
              />
              {errors.sellerEmail && <p className="text-red-500 text-sm mt-1 text-left">{errors.sellerEmail}</p>}
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <label htmlFor="password" className="text-[#202224] text-left">Password</label>
                <Link to="/seller/forgot-password" className="text-sm text-[#202224] hover:underline">Forgot password?</Link>
              </div>
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.sellerPassword ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-600`}
                  placeholder="Enter your password"
                  value={sellerPassword}
                  onChange={({ target: { value } }) => setPassword(value)}
                />
                <button
                  type="button"
                  className="absolute right-2 top-2 text-gray-700"
                  onClick={togglePasswordVisibility}
                  aria-label={passwordVisible ? "Hide password" : "Show password"}
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {errors.sellerPassword && <p className="text-red-500 text-sm mt-1 text-left">{errors.sellerPassword}</p>}
            </div>

            <div className="mb-4 flex items-center">
              <input type="checkbox" id="remember" className="mr-2" />
              <label htmlFor="remember" className="text-gray-700">Remember me</label>
            </div>

            {errors.server && <p className="text-red-500 text-sm mt-1 text-left">{errors.server}</p>}

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
              disabled={loading}
            >
              {loading ? 'Signing In...' : 'Sign In'}
            </button>
          </form>

          <div className="mt-4 text-center">
            <span className="text-gray-700">Don’t have an account? </span>
            <Link to="/seller/register" className="text-blue-600 hover:underline">Create account</Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default SellerLogin;
