import React from 'react';
import { Link } from 'react-router-dom';
import { FaTabletAlt } from 'react-icons/fa';
import { MdSmartphone } from 'react-icons/md';
import { IoLaptopOutline } from 'react-icons/io5';

const AccessoryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="48" height="48">
    <path
      style={{ fill: "#333333" }}
      d="m 76.8,7.9 -12.9,11.6 0,6.4 1.5,0.8 1.4,-5.1 10,-8.6 z m 20.3,9.3 -13.7,11.6 0,5.8 5,2.1 -13.7,13 0,18.8 15.9,-17.3 -1.5,-7.9 10.2,-10.2 z m -29.6,10.2 -14.4,12.3 0,14.4 4.4,-12.3 10,-8.7 z"
    />
    <path
      style={{ fill: "#999999" }}
      d="M 63.9,19.5 48.1,12.3 62.5,1.16 76.8,7.9 M 83.4,28.8 66.8,21.6 80.4,10.1 97.1,17.2 M 74.7,49.7 57.5,41.8 71.9,29.5 88.4,36.7 m -35.3,3 -15.8,-7.2 13.6,-12.3 16.6,7.2"
    />
    <path
      style={{ fill: "#cccccc" }}
      d="m 65.4,26.7 1.4,-5.1 16.6,7.2 0,5.8 -11.5,-5.1 -4.4,3.6 0,-5.7 z m -23.1,0.7 5.8,-15.1 15.8,7.2 0,6.4 -13,-5.7 z m -10.6,18.7 5.6,-13.6 15.8,7.2 0,14.4 4.4,-12.3 17.2,7.9 0,18.8 z"
    />
    <path
      style={{ fill: "none", stroke: "#111111", strokeWidth: 1.5 }}
      d="m 76.2,36.1 c -1.5,-2.2 -4.3,-2.2 -5.1,-0.8 -0.7,1.4 3.6,2.9 1.5,5 -0.7,0.8 -4.3,0 -5.1,-2.1 M 81.2,13 l -2.9,5 4.4,-0.8 0,3.7 5.7,-3.7 M 63.2,9.4 63.9,12.3 M 66.1,6.49 C 64.7,5.05 61.7,5.05 59.6,7.2 c -1.5,1.5 0,3.6 2.9,3.6 2.2,0 5,-2.1 3.6,-4.31 z m -16.6,20.91 4.4,2.1 m -8,-0.7 8.6,-2.9 -2.1,6.6"
    />
    <path
      style={{ fill: "none", stroke: "#222222", strokeWidth: 1 }}
      d="M 42.3,27.4 48.1,12.3 62.5,1.16 76.8,7.9 l 0,5.1 m -24.4,43.9 5.1,-15.1 14.4,-12.3 16.5,7.2 2.2,14.5 -15.9,17.3 -43,-22.4 5.6,-13.6 13.6,-12.3 16.6,7.2 0,5.7 m -2.1,-6.4 1.4,-5.1 13.6,-11.5 16.7,7.1 2.2,15.9 -10.2,10.2"
    />
    <path
      style={{ fill: "#444", stroke: "#333" }}
      d="M 19.9,46.4 C 0.918,35.2 20.7,22.3 16.2,13.5 10.6,6.5 4.43,8.59 -0.014,7.13 L -0.339,4.14 C 9.85,4.69 16.1,6.69 19.2,12.7 c 6.3,8.1 -15.28,21.9 4.1,32 0,0.6 -3.4,1.7 -3.4,1.7 z"
    />
    <path
      style={{ fill: "#444", stroke: "#333", strokeWidth: 1.5 }}
      d="M 44.9,46.4 C 55.2,51.5 62,61.8 65.4,72 c 1.2,5.7 2.3,17.1 -8,21.6 C 48.3,98.2 39.2,99.3 27.9,91.4 18.8,83.4 9.25,72 5.85,67.5 3.57,64 2.55,61.8 3,57.2 3,53.8 4.82,52.7 11.4,49.8 26.7,42.9 31.3,44 37,44 c 4.5,0 7.9,2.4 7.9,2.4 z"
    />
    <path
      style={{ fill: "#626262", stroke: "#ccc" }}
      d="m 55.2,53.8 c 1.1,3.4 -13.7,8 -13.7,8 0,0 -8,-12 -18.2,-16.5 0,0 8,-1.3 12.5,-1.3 3.4,0 6.9,0.7 12.5,4.7 3.5,2.8 6.9,5.1 6.9,5.1 z M 6.87,51.5 C 14.2,53.8 19.9,61.8 22.2,64 c 2.2,2.3 3.4,2.3 6.8,2.3 5.7,-1.1 12.5,-4.5 12.5,-4.5 0,0 -9.1,-12 -19.3,-16.5 -3.4,1.1 -10.8,4.5 -15.33,6.2 z"
    />
    <path
      style={{ fill: "#333", stroke: "#ccc", strokeWidth: 1.5 }}
      d="m 26.7,47.5 c -2.3,2.3 5.7,8.6 8,6.3 2.3,-2.3 -4.6,-7.4 -8,-6.3 z"
    />
    <path
      style={{ fill: "none", stroke: "#222", strokeWidth: 1.5 }}
      d="M 44.9,46.4 C 55.2,51.5 62,61.8 65.4,72 c 1.2,5.7 2.3,17.1 -8,21.6 C 48.3,98.2 39.2,99.3 27.9,91.4 18.8,83.4 9.25,72 5.85,67.5 3.57,64 2.55,61.8 3,57.2 3,53.8 4.82,52.7 11.4,49.8 26.7,42.9 31.3,44 37,44 c 4.5,0 7.9,2.4 7.9,2.4 z"
    />
  </svg>
);

const FeaturedCategories = () => {
  return (
    <section className="bg-white py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-semibold mb-8 text-center">Featured Categories</h2>
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 md:gap-6">
          <Link to="/home?category=tablets" className="bg-gray-100 rounded-lg overflow-hidden hover:shadow-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center h-20 md:h-24 bg-blue-200">
              <FaTabletAlt className="text-3xl md:text-4xl text-blue-600" />
            </div>
            <div className="p-3 md:p-4 text-center">
              <h3 className="text-sm md:text-lg font-semibold">Tablets</h3>
            </div>
          </Link>

          <Link to="/home?category=smartphones" className="bg-gray-100 rounded-lg overflow-hidden hover:shadow-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center h-20 md:h-24 bg-green-200">
              <MdSmartphone className="text-3xl md:text-4xl text-green-600" />
            </div>
            <div className="p-3 md:p-4 text-center">
              <h3 className="text-sm md:text-lg font-semibold">Smartphones</h3>
            </div>
          </Link>

          <Link to="/home?category=laptop" className="bg-gray-100 rounded-lg overflow-hidden hover:shadow-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center h-20 md:h-24 bg-yellow-200">
              <IoLaptopOutline className="text-3xl md:text-4xl text-yellow-600" />
            </div>
            <div className="p-3 md:p-4 text-center">
              <h3 className="text-sm md:text-lg font-semibold">Laptops</h3>
            </div>
          </Link>

          <Link to="/home?category=accessories" className="bg-gray-100 rounded-lg overflow-hidden hover:shadow-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center h-20 md:h-24 bg-purple-200">
              <AccessoryIcon className="text-3xl md:text-4xl text-purple-600" />
            </div>
            <div className="p-3 md:p-4 text-center">
              <h3 className="text-sm md:text-lg font-semibold">Accessories</h3>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FeaturedCategories;